/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../Members/style.css";
import { useDispatch } from "react-redux";
import api from "../../Config/Api";
import moment from "moment";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Checkbox,
} from "@mui/material";
import {
  Button,
  Space,
  DatePicker,
  Radio,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Select,
  message,
  Spin,
} from "antd";
// import { Box, Typography, Button, Input, CircularProgress,  } from '@mui/material';
import { getSetting } from "../../Action/GetSetting";

import { AddDfcFees } from "../../Action/DfcFees";
const { Title } = Typography;
// Action
const mapDispatch = (dispatch) => {
  return {
    // fees action call
    addFess: (payload) => dispatch(AddDfcFees(payload)),
  };
};

const mapState = (props) => ({
  SelectLoader: props?.MemberReducer?.isloading,
  // ResponseCode: props?.MemberReducer?.ResponseCode,
  // error: props?.MemberReducer?.error,
  // errorDetails: props?.MemberReducer?.errorDetails,
  success: props?.MemberReducer?.success,
  successDetails: props?.MemberReducer?.successDetails,
  MemberDetails: props?.MemberReducer?.MemberDetails,
});

function AddFees(store) {
  const [form] = Form.useForm();
  const formRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dfcdata = useSelector((state) => state.FeesReducer);
  const [receiptStartNo, setReceiptStartNo] = useState(null); // To store receipt start number
  const [currentReceiptNo, setCurrentReceiptNo] = useState(null); // To manage current receipt number
  const [memberNo, setMemberNo] = useState("");
  const [maintainceCharge, setMaintainceCharge] = useState(null);
  const [maintainceChargeStatus, setMaintainceChargeStatus] =
    useState("DISABLE");

  const [dfcLateMonth, setDfcLateMonth] = useState(null);
  const [dfcLateFees, setLateFees] = useState(null);
  const setting = useSelector((state) =>
    state.setting ? state.setting.data : {}
  );

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  // Edit
  useEffect(() => {
    id && store?.SingalMemberDetail(id);
    // console.log("data--------->", dfcdata);
    // eslint-disable-next-line
  }, [id, dfcdata]);

  useEffect(() => {
    form.setFieldsValue({
      PaymentMethod: "CASH",
      // by default payment method is cash in payload......
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (store?.success && store?.successDetails?.data?.message) {
      console.log("here-succc");
      form.resetFields();
      store.resetState();
    }

    // eslint-disable-next-line
  }, [store?.success && store?.successDetails?.data?.message]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormSubmit = async (params) => {
    try {
      const payload = {
        groupId: params?.GroupId,
        amount: params?.Amount,
        paymentMethod: params?.PaymentMethod,
        userId: params?.UserId,
        dfcNumber: params?.DfcNumber,
        receiptNo: currentReceiptNo, // Use the fetched receipt number
        receiptDate: params?.ReceiptDate,
        membersFees: params?.MembersFees,
      };

      if (params?.PaymentMethod === "CHEQUE") {
        payload.paymentDetails = {
          bank: params?.BankName,
          branch: params?.BranchName,
          chequeNo: params?.ChequeNo,
          chequeDate: params?.ChequeDate,
        };
      }

      const response = await api.post("/admin/transaction-details", payload, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        message.success("Transaction submitted successfully!");
        form.resetFields();
      } else {
        message.error(`Failed to submit: ${response.data.message}`);
      }
    } catch (error) {
      message.error(`An error occurred: ${error.message}`);
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await dispatch(getSetting());

        if (response?.payload) {
          const {
            Receipt_start_number,
            dfc_month,
            dfc_late_fees,
            Maintaince_charge,
            Maintaince_charge_status,
          } = response.payload;

          setCurrentReceiptNo(Receipt_start_number);
          form.setFieldsValue({ receiptNumber: Receipt_start_number });

          if (dfc_month) {
            setDfcLateMonth(dfc_month);
            form.setFieldsValue({ dfcLateMonth: dfc_month });
          }

          if (dfc_late_fees) {
            setLateFees(dfc_late_fees);
            form.setFieldsValue({ dfc_late_fees: dfc_late_fees });
          }

          if (Maintaince_charge_status === "ENABLE") {
            setMaintainceCharge(Maintaince_charge);
            form.setFieldsValue({ Maintaince_charge: Maintaince_charge });
          } else {
            setMaintainceCharge(0);
            form.setFieldsValue({ Maintaince_charge: 0 });
          }

          if (Maintaince_charge_status) {
            setMaintainceChargeStatus(Maintaince_charge_status);
            form.setFieldsValue({
              Maintaince_charge_status: Maintaince_charge_status,
            });
          }
        }
      } catch (err) {
        message.error("Failed to fetch settings.");
        console.error(err);
      }
    };

    fetchSettings();
  }, [dispatch, form]);

  useEffect(() => {
    if (currentReceiptNo !== null) {
      form.setFieldsValue({ receiptNo: currentReceiptNo });
    }
  }, [currentReceiptNo, form]);

  const [dfcNumber, setDfcNumber] = useState("");
  const [dfcDetails, setDfcDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createdDate, setCreatedDate] = useState(""); // For created date

  const handleSubmit = async () => {
    if (!dfcNumber) {
      message.error("At least one DFC number is required");
      return;
    }

    if (!/^\d+(,\d+)*$/.test(dfcNumber)) {
      message.error("Please insert valid DFC numbers (comma-separated)");
      return;
    }

    setLoading(true);

    const currentDfcNumber = dfcNumber;

    form.resetFields(); // Resets all form fields
    setDfcDetails([]);
    setCreatedDate("");
    setMembers([]);
    setTotalFees(0);
    setTotallateFees(0);
    setCombinedTotal(0);
    setUserId("");
    setIsGroupDataDisabled(false);
    setIsMemberNoDisabled(false);
    setMemberDetails([]);
    setPaymentMethod("CHEQUE");
    setCashDetails({ receiptNo: "", receiptDate: null });
    setChequeDetails({
      bankName: "",
      branchName: "",
      chequeNo: "",
      chequeDate: null,
      receiptNo: "",
      chequeReturnCharge: "",
    });

    // Restore dfcNumber after reset
    setDfcNumber(currentDfcNumber);
    form.setFieldsValue({ "DFC Numbers": currentDfcNumber });

    try {
      const response = await api.get("/admin/fetchDfcDetails", {
        params: { dfcNumber: dfcNumber, internalGroupId: groupData },
      });

      if (response.status === 200) {
        const { data, createdDate } = response.data;
        setDfcDetails(data);
        setCreatedDate(createdDate);
        message.success("Data fetched successfully");
      } else {
        message.error(response.data.message || "Failed to fetch details");
      }
    } catch (error) {
      message.error(
        error.response?.data?.message || "Please insert valid DFC number."
      );
    } finally {
      setLoading(false);
    }
  };

  const [groupData, setGroupData] = useState("");
  const [members, setMembers] = useState([]);
  const [totalFees, setTotalFees] = useState(0);
  const [totallateFees, setTotallateFees] = useState(0);
  const [combinedTotal, setCombinedTotal] = useState(0);
  const [userId, setUserId] = useState("");
  const [isGroupDataDisabled, setIsGroupDataDisabled] = useState(false);
  const [isMemberNoDisabled, setIsMemberNoDisabled] = useState(false);
  const [memberDetails, setMemberDetails] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("CHEQUE");
  const [cashDetails, setCashDetails] = useState({
    receiptNo: "",
    receiptDate: null,
  });
  const [chequeDetails, setChequeDetails] = useState({
    bankName: "",
    branchName: "",
    chequeNo: "",
    chequeDate: null,
    receiptNo: "",
    // bankCharges: "",
    chequeReturnCharge: "",
    receiptDate: null,
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  // const [dfcNumber, setDfcNumber] = useState(""); // Add this state to manage dfcNumber
  const [receiptDate, setReceiptDate] = useState("");
  useEffect(() => {
    const isDisabled =
      !dfcNumber ||
      !combinedTotal ||
      !paymentMethod ||
      (paymentMethod === "CHEQUE" &&
        (chequeDetails.receiptNo ||
          !chequeDetails.bankName ||
          !chequeDetails.branchName ||
          !chequeDetails.chequeNo ||
          !chequeDetails.chequeDate));

    setIsSubmitDisabled(isDisabled);
  }, [
    dfcNumber,
    groupData,
    combinedTotal,
    paymentMethod,
    cashDetails,
    chequeDetails,
  ]);

  const onGroupDataChange = (e) => {
    const value = e.target.value;
    setGroupData(value);

    if (value) {
      setMemberNo("");
      setIsMemberNoDisabled(true);
      setIsGroupDataDisabled(false);
    } else {
      setIsMemberNoDisabled(false);
    }
  };

  const onMemberNoChange = (e) => {
    const value = e.target.value;
    setMemberNo(value);

    if (value) {
      setGroupData("");
      setIsGroupDataDisabled(true);
      setIsMemberNoDisabled(false);
    } else {
      setIsGroupDataDisabled(false);
    }
  };

  const fetchGroupData = async () => {
    if (!groupData && !memberNo) {
      message.error("Please enter a Group ID or Member No");
      return;
    }

    if (!dfcNumber) {
      message.error("Please enter a DFC number");
      return;
    }

    try {
      const response = await api.get("/admin/fetchGroupDetails", {
        params: { internalGroupId: groupData, dfcNumber, memberNo },
      });

      if (response.data.success) {
        if (
          response.data.message ===
          "All members have already paid their DFC fees."
        ) {
          message.success(response.data.message);
          setMembers([]); // Clear members
          return;
        }

        const fetchedMembers = response.data.data?.members || []; // Ensure it is an array

        if (fetchedMembers.length === 0) {
          message.warning("No members found.");
          setMembers([]); // Clear previous members data
          setMemberDetails([]); // Clear member details
          return;
        }

        setMembers(fetchedMembers);

        let groupId = groupData;
        if (fetchedMembers.length > 0) {
          groupId = fetchedMembers[0].groupId;
          setGroupData(groupId);

          setMemberDetails(
            fetchedMembers.map((member) => ({
              _id: member._id,
              memberId: member.memberNo,
              fees: 300,
              lateFees: member.lateFees || 0,
            }))
          );

          const fields = fetchedMembers.reduce((acc, member, index) => {
            acc[`MemberId_${index}`] = member.memberNo;
            acc[`Member_${index}`] = member.memberName;
            acc[`City_${index}`] = member.city;
            acc[`Fees_${index}`] = 300;
            acc[`MaintenanceCharge_${index}`] = 0;
            return acc;
          }, {});

          form.setFieldsValue(fields);
        }

        message.success("Data fetched successfully");
      } else {
        message.error("Failed to fetch group data");
      }
    } catch (error) {
      console.error("API error:", error);
      message.error("Failed to fetch group data. Please try again.");
    }
  };

  const handleSubmit1 = async () => {
    await fetchGroupData();
  };

  const updateTotals = (
    updatedDetails,
    updatedChequeDetails = chequeDetails,
    receiptDate
  ) => {
    if (!receiptDate) {
      receiptDate = moment().format("DD-MM-YYYY");
    }

    const formattedReceiptDate = moment(receiptDate, [
      "DD/MM/YYYY",
      "YYYY-MM-DD",
    ]).format("DD-MM-YYYY");

    let newTotalFees = 0;
    let newTotallateFees = 0;

    const updatedMembers = updatedDetails.map((member) => {
      if (!member.selected) return member;

      const created = moment(member.createdDate || createdDate).format(
        "DD-MM-YYYY"
      );
      const receipt = moment(formattedReceiptDate, "DD-MM-YYYY").format(
        "DD-MM-YYYY"
      );

      let calculatedLateFees = 0;

      if (
        moment(created, "DD-MM-YYYY", true).isValid() &&
        moment(receipt, "DD-MM-YYYY", true).isValid()
      ) {
        const monthsDiff = moment(receipt, "DD-MM-YYYY").diff(
          moment(created, "DD-MM-YYYY"),
          "months",
          true
        );
        console.log("Months Difference:", monthsDiff);

        if (monthsDiff >= 1) {
          if (monthsDiff > dfcLateMonth) {
            calculatedLateFees = dfcLateFees;
          } else {
            console.log("No Late Fee Applied");
          }
        } else {
          calculatedLateFees = 0;
        }
      } else {
        console.log("Invalid Date(s) - Late Fee Skipped");
      }

      newTotalFees += (member.fees || 300) + maintainceCharge;
      newTotallateFees += calculatedLateFees;

      return { ...member, lateFees: calculatedLateFees };
    });

    let additionalCharges = 0;
    if (paymentMethod === "CHEQUE") {
      additionalCharges += parseFloat(
        updatedChequeDetails.chequeReturnCharge || 0
      );
    }

    const newCombinedTotal =
      newTotalFees + newTotallateFees + additionalCharges;

    setTotalFees(newTotalFees);
    setTotallateFees(newTotallateFees);
    setCombinedTotal(newCombinedTotal);

    setMemberDetails(updatedMembers);

    const updatedFields = updatedMembers.reduce((acc, member, index) => {
      acc[`LateFees_${index}`] = member.lateFees;
      return acc;
    }, {});

    form.setFieldsValue(updatedFields);
  };

  useEffect(() => {
    if (chequeDetails.receiptDate && createdDate) {
      const formattedCreatedDate = moment(createdDate).format("DD-MM-YYYY");

      applyLateFeeAndUpdateTotals(
        formattedCreatedDate,
        chequeDetails.receiptDate
      );
    }
  }, [chequeDetails.receiptDate, createdDate]);

  const applyLateFeeAndUpdateTotals = (createdDate, receiptDate) => {
    try {
      if (!receiptDate) {
        receiptDate = moment().format("DD-MM-YYYY");
      }

      if (!createdDate) {
        return;
      }

      setMemberDetails((prevDetails) => {
        const updatedDetails = prevDetails.map((member, index) => {
          const created = moment(createdDate, "DD-MM-YYYY");
          const receipt = moment(receiptDate, "DD-MM-YYYY");

          if (!created.isValid() || !receipt.isValid()) {
            return member;
          }

          const monthsDiff = receipt.diff(created, "months", true);

          const isLate = monthsDiff > dfcLateMonth;

          const updatedLateFees = isLate ? dfcLateFees : 0;

          if (member.lateFees !== updatedLateFees) {
            return { ...member, lateFees: updatedLateFees };
          }

          return member;
        });

        updateTotals(updatedDetails, chequeDetails, receiptDate);

        const updatedFields = updatedDetails.reduce((acc, member, index) => {
          acc[`LateFees_${index}`] = member.lateFees;
          return acc;
        }, {});

        form.setFieldsValue(updatedFields);

        return updatedDetails;
      });
    } catch (error) {
      console.error("Error in applyLateFeeAndUpdateTotals:", error);
    }
  };

  const handleReceiptDateChange = (date, dateString) => {
    if (!date) return;

    const formattedDate = moment(date, "DD-MM-YYYY").format("DD-MM-YYYY");

    setReceiptDate(formattedDate); // Update state
    setChequeDetails((prevDetails) => ({
      ...prevDetails,
      receiptDate: formattedDate,
    }));

    console.log("Receipt Date Selected:", formattedDate);

    updateTotals(memberDetails, chequeDetails, formattedDate);
  };

  useEffect(() => {
    form.setFieldsValue({ receiptDate });

    updateTotals(memberDetails, chequeDetails, receiptDate);
  }, [receiptDate]);

  const handleChequeDateChange = (date, dateString) => {
    // Update both chequeDate and receiptDate
    setChequeDetails((prevDetails) => ({
      ...prevDetails,
      chequeDate: dateString, // Update chequeDate
    }));
    applyLateFeeAndUpdateTotals(createdDate, dateString);
  };

  const handleCashDateChange = (date, dateString) => {
    setCashDetails((prevDetails) => ({
      ...prevDetails,
      receiptDate: dateString,
    }));

    // Automatically apply late fees and update totals
    applyLateFeeAndUpdateTotals(createdDate, dateString);
  };

  const handleChequeDetailsChange = (e) => {
    const { name, value } = e.target;

    setChequeDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, [name]: value };

      updateTotals(memberDetails, updatedDetails);

      return updatedDetails;
    });
  };

  const Finalsubmit = async () => {
    try {
      setLoading(true);
      const currentDate = moment().format("DD-MM-YYYY");

      const updatedCashDetails = {
        ...cashDetails,
        receiptDate: cashDetails.receiptDate || currentDate,
      };

      const updatedChequeDetails = {
        ...chequeDetails,
        receiptDate: chequeDetails.receiptDate || currentDate,
      };

      const receiptDate =
        updatedCashDetails.receiptDate ||
        updatedChequeDetails.receiptDate ||
        currentDate;

      const updatedMemberDetails = memberDetails
        .filter((member) => member.selected && member.fees > 0)
        .map((member) => ({
          ...member,
          Maintaince_charge: maintainceCharge || 0,
        }));

      const paymentUserIds = updatedMemberDetails.map(
        (member) => member.userId
      );

      const payload = {
        groupId: groupData,
        amount: combinedTotal,
        paymentMethod: paymentMethod,
        dfcNumber,
        membersFees: updatedMemberDetails,
        receiptNo:
          updatedCashDetails.receiptNo || updatedChequeDetails.receiptNo,
        receiptDate: receiptDate,
        paymentUserIds,
        ...(paymentMethod === "CASH" && { cashDetails: updatedCashDetails }),
        ...(paymentMethod === "CHEQUE" && {
          paymentDetails: {
            ...updatedChequeDetails,
            chequeReturnCharge: parseFloat(
              chequeDetails.chequeReturnCharge || 0
            ),
          },
        }),
      };

      const response = await api.post("/admin/transaction-details", payload, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        message.success("Transaction details submitted successfully!");
        setCurrentReceiptNo((prevReceiptNo) => prevReceiptNo + 1);

        const currentDfcNumber = dfcNumber;

        form.resetFields();

        setGroupData("");
        setCreatedDate("");
        setMembers([]);
        setTotalFees(0);
        setTotallateFees(0);
        setCombinedTotal(0);
        setUserId("");
        setIsGroupDataDisabled(false);
        setIsMemberNoDisabled(false);
        setMemberDetails([]);
        setDfcNumber(currentDfcNumber);
        form.setFieldsValue({ "DFC Numbers": currentDfcNumber });

        setPaymentMethod("CHEQUE");

        setChequeDetails(() => ({
          bankName: "",
          branchName: "",
          chequeNo: "",
          chequeDate: null,
          receiptNo: "",
          chequeReturnCharge: "",
          receiptDate: null,
        }));

        form.setFieldsValue({
          bankName: "",
          branchName: "",
          chequeNo: "",
          chequeDate: null,
          chequeReturnCharge: "",
          receiptDate: null,
        });

        setCashDetails({ receiptNo: "", receiptDate: null });
      } else {
        message.error(`Failed to submit: ${response.data.message}`);
      }
    } catch (error) {
      message.error(
        `An error occurred while submitting the transaction details. ${error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <SiteLayout>
      <div className="headingDiv">
        <h2 style={{ textAlign: "center", margin: "0px" }}>DFC Fees</h2>
      </div>

      <div className="subscription-container center-content">
        <Row gutter={2}>
          <Col
            //changes start
            xxl={20}
            xl={20}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ margin: "0 auto" }}
            //changes end
          >
            <div
              style={{ padding: "30px" }}
              className="checkout-form-container gig-sure-card-wrapper"
            >
              <Form
                scrollToFirstError={true}
                onFinish={handleFormSubmit}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                form={form}
                name="dfc"
                autoComplete="off"
                ref={formRef}
              >
                <Row className="mt-1">
                  <Col span={24}>
                    <div className="dfc-details-container">
                      {/* Title */}
                      <Title level={4} className="dfc-title">
                        DFC Details
                      </Title>

                      {/* Receipt Number Display */}
                      <div className="receipt-number">
                        <strong>Receipt Number:</strong> {currentReceiptNo + 1}
                        {""}
                      </div>
                    </div>
                  </Col>
                </Row>

                <div>
                  {/* DFC Number Search Form */}
                  <Row gutter={10}>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Row gutter={10} align="middle">
                        <Col span={16}>
                          <Form.Item name="DFC Numbers" label="DFC Numbers">
                            <Input
                              className="form-input-border"
                              autoComplete="off"
                              placeholder="Enter DFC Numbers"
                              value={dfcNumber}
                              onChange={(e) => setDfcNumber(e.target.value)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            onClick={handleSubmit}
                            sx={{ width: "100%" }}
                            style={{
                              backgroundColor: "#0a0b55",
                              color: "#fff",
                              borderColor: "#52c41a",
                            }}
                          >
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Search"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* DFC Details Table */}
                  {dfcDetails.length > 0 ? (
                    <TableContainer component={Paper} sx={{ mt: 3 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Member No</TableCell>
                            <TableCell>Member Name</TableCell>
                            <TableCell>Date of Death</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dfcDetails.map((detail, index) => (
                            <TableRow key={index}>
                              <TableCell>{detail.memberNo}</TableCell>
                              <TableCell>{detail.memberName}</TableCell>
                              <TableCell>
                                {detail.dateOfDeath
                                  ? new Date(
                                      detail.dateOfDeath
                                    ).toLocaleDateString()
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography variant="body2" mt={3}></Typography>
                  )}
                </div>

                {/* Group id part */}
                <Form form={form}>
                  <Row gutter={10}>
                    <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                      <Row
                        gutter={10}
                        align="middle"
                        style={{ marginTop: "20px" }}
                      >
                        <Col span={16}>
                          <Form.Item name="Group" label="Group ID">
                            <Input
                              className="form-input-border"
                              autoComplete="off"
                              placeholder="Enter Group ID"
                              value={groupData}
                              onChange={onGroupDataChange}
                              disabled={isGroupDataDisabled} // Dynamically controlled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Button
                            variant="contained"
                            color="primary"
                            className="btn"
                            onClick={fetchGroupData}
                            sx={{ width: "100%" }}
                            style={{
                              backgroundColor: "#0a0b55",
                              color: "#fff",
                              borderColor: "#52c41a",
                            }}
                            disabled={!groupData && !memberNo} // Only enable when one of the fields is filled
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Col>

                    {/* Member No Field */}
                    <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                      <Row
                        gutter={10}
                        align="middle"
                        style={{ marginTop: "20px" }}
                      >
                        <Col span={16}>
                          <Form.Item name="MemberNo" label="Member No">
                            <Input
                              className="form-input-border"
                              autoComplete="off"
                              placeholder="Enter Member No"
                              value={memberNo}
                              onChange={onMemberNoChange}
                              disabled={isMemberNoDisabled}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Button
                            variant="contained"
                            color="primary"
                            className="btn"
                            onClick={fetchGroupData}
                            sx={{ width: "100%" }}
                            style={{
                              backgroundColor: "#0a0b55",
                              color: "#fff",
                              borderColor: "#52c41a",
                            }}
                            disabled={!memberNo && !groupData}
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {members.map((member, index) => (
                    <div
                      style={{
                        padding: "20px",
                        background: "#f9f9f9",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        key={index}
                        style={{
                          border: "1px solid #ddd",
                          padding: "15px",
                          borderRadius: "8px",
                          marginBottom: "10px",
                          background: "#fff",
                        }}
                      >
                        <Row gutter={16} align="middle">
                          {/* Checkbox */}
                          <Col xl={2} lg={3} md={4} sm={24} xs={24}>
                            <Checkbox
                              checked={memberDetails[index]?.selected || false}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                setMemberDetails((prevDetails) => {
                                  const updatedDetails = [...prevDetails];
                                  updatedDetails[index] = {
                                    ...updatedDetails[index],
                                    selected: checked,
                                  };
                                  updateTotals(updatedDetails);
                                  return updatedDetails;
                                });
                              }}
                            />
                          </Col>

                          {/* First Row */}
                          <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name={`MemberId_${index}`}
                              label="Member ID"
                            >
                              <Input
                                placeholder="Member ID"
                                disabled
                                style={{
                                  color: "#808080",
                                  background: "#f5f5f5",
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xl={8} lg={12} md={16} sm={24} xs={24}>
                            <Form.Item
                              name={`Member_${index}`}
                              label="Member Name"
                            >
                              <Input
                                placeholder="Member Name"
                                disabled
                                style={{
                                  color: "#808080",
                                  background: "#f5f5f5",
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                            <Form.Item name={`City_${index}`} label="City">
                              <Input
                                placeholder="City"
                                disabled
                                style={{
                                  color: "#808080",
                                  background: "#f5f5f5",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                            <Form.Item
                              name={`Fees_${index}`}
                              label="Contribution"
                              rules={[
                                {
                                  pattern: /^(?:\d*)$/,
                                  message: "Please enter a valid amount",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Contribution"
                                value={memberDetails[index]?.fees || 300}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        {/* Second Row with Left Margin */}
                        <Row
                          gutter={16}
                          align="middle"
                          style={{ marginLeft: "65px" }}
                        >
                          <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name={`LateFees_${index}`}
                              label="Late Payment Charge"
                              rules={[
                                {
                                  pattern: /^(?:\d*)$/,
                                  message: "Please enter a valid amount",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Late Payment Charges"
                                value={
                                  form.getFieldValue(`LateFees_${index}`) || 0
                                } // ✅ Get updated value from form
                                disabled
                              />
                            </Form.Item>
                          </Col>

                          <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                            <Form.Item
                              name={`MaintenanceCharge_${index}`}
                              label="Maintenance Charge"
                              rules={[
                                {
                                  pattern: /^(?:\d*)$/,
                                  message: "Please enter a valid amount",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Maintenance Charge"
                                value={
                                  memberDetails[index]?.maintenanceCharge ||
                                  maintainceCharge ||
                                  0
                                }
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        {/* Divider */}
                        <Row>
                          <Col span={24}>
                            <hr
                              style={{
                                margin: "15px 0",
                                borderTop: "1px solid #ddd",
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ))}
                </Form>
                {/* Group id part end here  */}

                {/* the total of fees and lateFees and there total also */}
                {members.length > 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <h4>Total Amount</h4>
                    <Row gutter={10}>
                      <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                        <Form.Item label="Combined Total">
                          <Input
                            value={combinedTotal.toFixed(2)}
                            disabled={true}
                            style={{ color: "#808080" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}

                <Form>
                  <Row className="mt-1">
                    <Col span={24}>
                      <Title level={4}>Payment</Title>
                    </Col>
                  </Row>

                  {/* radio button */}
                  <Form.Item
                    name="PaymentMethod"
                    label="Payment Method #"
                    initialValue={paymentMethod}
                  >
                    <Radio.Group
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <Radio value="CASH">Cash</Radio>
                      <Radio value="CHEQUE">Cheque</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {/* input for cheque */}
                  {paymentMethod === "CHEQUE" && (
                    <Row gutter={10}>
                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          // name="BankName"
                          label="Bank Name #"
                          className="field-with-prefix"
                          rules={[
                            {
                              required: true,
                              message: "Bank Name is required",
                            },
                            {
                              pattern: /^([^0-9]*)$/,
                              message: "Please Insert Valid Bank Name",
                            },
                          ]}
                        >
                          <Input
                            name="bankName"
                            autoComplete="off"
                            placeholder="Bank Name"
                            value={chequeDetails.bankName}
                            onChange={handleChequeDetailsChange}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          // name="BranchName"
                          label="Branch Name #"
                          className="field-with-prefix"
                          rules={[
                            {
                              required: true,
                              message: "Branch Name is required",
                            },
                            {
                              pattern: /^([^0-9]*)$/,
                              message: "Please Insert Valid Branch Name",
                            },
                          ]}
                        >
                          <Input
                            name="branchName"
                            autoComplete="off"
                            placeholder="Branch Name"
                            value={chequeDetails.branchName}
                            onChange={handleChequeDetailsChange}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          // name="ChequeNo"
                          label="Cheque/Draft #"
                          className="field-with-prefix"
                          rules={[
                            {
                              required: true,
                              message: "Cheque No. is required",
                            },
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Please Insert Valid Cheque/Draft Id",
                            },
                          ]}
                        >
                          <Input
                            name="chequeNo"
                            autoComplete="off"
                            placeholder="Cheque / Draft"
                            value={chequeDetails.chequeNo}
                            onChange={handleChequeDetailsChange}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          // name="ChequeDate"
                          label="Date #"
                          className="field-with-prefix"
                          rules={[
                            { required: true, message: "Date is required" },
                          ]}
                        >
                          <DatePicker
                            placeholder="Cheque date"
                            style={{ width: "100%" }}
                            format="DD-MM-YYYY"
                            value={
                              chequeDetails.chequeDate
                                ? moment(chequeDetails.chequeDate, "DD-MM-YYYY")
                                : null
                            }
                            onChange={handleChequeDateChange}
                            disabledDate={(current) => current && current > moment().endOf("day")}

                          />
                        </Form.Item>
                      </Col>

                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          label="Receipt Number"
                          name="receiptNo"
                          rules={[
                            {
                              required: false,
                              message: "Receipt number is required",
                            },
                          ]}
                        >
                          <Input disabled value={currentReceiptNo + 1} />{" "}
                        </Form.Item>
                      </Col>

                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="receiptDate"
                          label="Receipt Date"
                          className="field-with-prefix"
                          rules={[
                            { required: true, message: "Date is required" },
                          ]}
                        >
                          <DatePicker
                            placeholder="Receipt date"
                            style={{ width: "100%" }}
                            format="DD-MM-YYYY"
                            value={
                              chequeDetails.receiptDate
                                ? moment(
                                    chequeDetails.receiptDate,
                                    "DD-MM-YYYY"
                                  )
                                : null
                            }
                            defaultValue={moment()}
                            onChange={handleReceiptDateChange}
                            disabledDate={(current) =>
                              current && current > moment().endOf("day")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          // name="chequeReturnCharge"
                          label="Cheque Return Charge"
                          className="field-with-prefix"
                          rules={[
                            {
                              required: true,
                              message: "Cheque Return Charge is required",
                            },
                            {
                              pattern: /^(?:\d*)$/,
                              message:
                                "Please Insert Valid Cheque Return Charge",
                            },
                          ]}
                        >
                          <Input
                            name="chequeReturnCharge"
                            autoComplete="off"
                            placeholder="Cheque Return Charge"
                            value={chequeDetails.chequeReturnCharge}
                            onChange={handleChequeDetailsChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {/* input for cash */}
                  {paymentMethod === "CASH" && (
                    <Row gutter={10}>
                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="ReceiptDate"
                          label="Receipt Date #"
                          className="field-with-prefix"
                          rules={[
                            { required: true, message: "Date is required" },
                          ]}
                        >
                          <DatePicker
                            placeholder="Receipt date"
                            style={{ width: "100%" }}
                            format="DD-MM-YYYY"
                            value={
                              cashDetails.receiptDate
                                ? moment(cashDetails.receiptDate, "DD-MM-YYYY")
                                : null
                            }
                            defaultValue={moment()} // Default to current date
                            onChange={handleCashDateChange}
                            disabledDate={(current) => current && current > moment().endOf("day")}

                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form>
                {/*  payment  end */}
                {/* success */}
                <Row>
                  <Col span={1} style={{ textAlign: "end" }}>
                    <Form.Item className="submit">
                      <Button
                        size="large"
                        className="success-btn"
                        style={{
                          marginTop: "10px",
                          backgroundColor: isSubmitDisabled
                            ? "#bbb"
                            : "#ff5722",
                          color: "#fff",
                          borderColor: isSubmitDisabled ? "#bbb" : "#d84315",
                          transition: "background 0.3s ease",
                        }}
                        htmlType="submit"
                        onClick={Finalsubmit}
                        disabled={isSubmitDisabled || loading}
                      >
                        {loading ? <Spin size="small" /> : "Submit"}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(AddFees);
