import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Row,
  Col,
  Descriptions,
  Badge,
  Skeleton,
} from "antd";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { MemberDetailAction } from "../../Action/Member";
import moment from "moment";
import { SiteConstants } from "../../Config/SiteConstants";

const mapState = (props) => ({
  // Member: props.MemberReducer.MemberArray,
  SelectLoader: props?.MemberReducer?.isloading,
  MemberDetails: props?.MemberReducer?.MemberDetails,
});

const mapDispatch = (dispatch) => {
  return {
    SingalMemberDetail: (id) => dispatch(MemberDetailAction(id)),
  };
};

function Memberdetail(store) {
  let navigate = useNavigate();
  const { id } = useParams();
  const [MemberDetailData, setMemberDetailData] = useState(null);

  let { NA } = SiteConstants;

  useEffect(() => {
    store?.SingalMemberDetail(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (store?.MemberDetails) {
      setMemberDetailData(store?.MemberDetails);
    }
    // eslint-disable-next-line
  }, [store?.MemberDetails]);

  return (
    <SiteLayout>
      {/* <div className="headingDiv">
        <h2>Member Detail</h2>
      </div> */}

      <div className="pgheader">
        <Row>
          <Col xs={24} xl={24}>
            <PageHeader
              onBack={() => navigate(-1)}
              title="Go Back"
            ></PageHeader>
          </Col>
        </Row>
      </div>

      <div>
        <Row>
          <Col xs={24} xl={24}>
            <Card>
              <Skeleton
                active
                loading={store.SelectLoader}
                paragraph={{ rows: 25 }}
              >
                <Descriptions title="Member information" bordered>
                  <Descriptions.Item label="Member id" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.memberNo || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Email" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.email || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="First name" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.firstname || NA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Middle name" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.middlename || NA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Last name" span={4}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.lastname || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Birth date" span={2}>
                    <Badge
                      status="processing"
                      text={
                        moment(new Date(MemberDetailData?.birthDate)).format(
                          "DD/MM/YYYY"
                        ) || NA
                      }
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Gender" span={4}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.gender || NA}
                    />
                  </Descriptions.Item>
                </Descriptions>

                <br />

                <Descriptions title="Member contact details" bordered>
                  <Descriptions.Item label="Address Line 1" span={4}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.addressLine1 || NA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Address Line 2" span={4}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.addressLine2 || NA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Address Line 3" span={4}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.addressLine3 || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Landmark" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.landmark || NA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="City" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.city || NA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="District" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.district || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="State" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.state || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Pin code" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.pincode || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Group id" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.group || NA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Internal group id" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.internalGroupId || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Mobile number 1" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.mobileNumber1 || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Mobile number 2" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.mobileNumber2 || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Whatsapp number" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.watsappMobileNumber || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Joining date" span={2}>
                    <Badge
                      status="processing"
                      text={
                        moment(new Date(MemberDetailData?.joiningDate)).format(
                          "DD/MM/YYYY"
                        ) || NA
                      }
                    />
                  </Descriptions.Item>
                </Descriptions>

                <br />
                <Descriptions title="Nomination Details 1" bordered>
                  <Descriptions.Item label="Nomine name" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.nomination?.name || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Member id" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.nomination?.memberId || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Nominee relation" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.nomination?.relation || NA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Nominee date of birth" span={2}>
                    <Badge
                      status="processing"
                      text={
                        moment(
                          new Date(MemberDetailData?.nomination?.birthdate)
                        ).format("DD/MM/YYYY") || NA
                      }
                    />
                  </Descriptions.Item>
                </Descriptions>
                <br />
                <Descriptions title="Nomination Details 2" bordered>
                  <Descriptions.Item label="Nomine name" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.nomination2?.name || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Member id" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.nomination2?.memberId || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Nominee relation" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.nomination2?.relation || NA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Nominee date of birth" span={2}>
                    <Badge
                      status="processing"
                      text={
                        moment(
                          new Date(MemberDetailData?.nomination2?.birthdate)
                        ).format("DD/MM/YYYY") || NA
                      }
                    />
                  </Descriptions.Item>
                </Descriptions>
                <br />
                <Descriptions title="Other information" bordered>
                  <Descriptions.Item label="Education" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.education || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Marital Status" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.maritalStatus || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Membership status" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.membershipStatus || NA}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Member status" span={2}>
                    <Badge
                      status="processing"
                      text={MemberDetailData?.status || NA}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Skeleton>
            </Card>
          </Col>
        </Row>
      </div>

      <br />
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(Memberdetail);
