import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  message,
  Space,
  Button,
  Input,
  Tooltip,
  Switch,
  Tag,
  Popconfirm,
  Radio,
} from "antd";
import { Link } from "react-router-dom";
import Loader from "../../Component/Reusable/Loader/Loader";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";

import {
  EyeOutlined,
  SearchOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import { connect, useSelector } from "react-redux";
import {
  MemberListAction,
  DeleteMemberAction,
  MemberStatus,
} from "../../Action/Member";

import "./userstyle.scss";
import "./style.css";

const mapState = (props) => ({
  member: props?.MemberReducer,
  MemberArray: props?.MemberReducer?.MemberArray,
  SelectLoader: props?.MemberReducer?.isloading,
  Memberstatus: props?.MemberReducer?.MemberStatus,
});

const mapDispatch = (dispatch) => {
  return {
    MemberList: ({ page, pageSize }) =>
      dispatch(MemberListAction({ page, pageSize })),
    MemberDelete: (id) => dispatch(DeleteMemberAction(id)),
    MemberStatus: (payload) => dispatch(MemberStatus(payload)),
  };
};

function Members({
  MemberList,
  MemberDelete,
  MemberStatus,
  MemberArray,
  SelectLoader,
  member,
}) {
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [currentPage, setCurrentPage] = useState(1); //  set current page
  const [pageSize, setPageSize] = useState(5); //  per page ma 5
  const [totalCount, setTotalCount] = useState(0); //
  // let [MemberArray, setMemberArray] = useState([]);
  const [status, setStatus] = useState(null);
  const [reason, setReason] = useState("");
  const [isReasonRequired, setIsReasonRequired] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  //  start deep ....................

  const memberactionstatus = useSelector(
    (state) => state.MemberReducer.MmberStatus
  );

  function handleMemberStatus(checked, _id) {
    console.log("checked: ", checked);
    let statusaction = checked ? "INACTIVE" : "ACTIVE";
    const payload = {
      _id,
      status: statusaction,
    };
    MemberStatus(payload);
  }

  useEffect(() => {
    MemberList({ page: currentPage, pageSize });
  }, [currentPage, pageSize, MemberList]);

  useEffect(() => {
    if (member) {
      // console.log(member, "<<<<<<<<<<<<<here>>>>>>>>>>>>>");
      setTotalCount(member.totalItems);
    }
  }, [member]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true, closeDropdown: true });
    setSearchText("");
  };

  const handleMemberDelete = (_id) => {
    MemberDelete(_id);
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);

    setIsReasonRequired(
      selectedStatus === "INACTIVE" || selectedStatus === "CANCEL"
    );
  };

  const handleConfirmStatusChange = () => {
    if (!status) {
      message.error("Please select a status.");
      return;
    }

    if (isReasonRequired && !reason.trim()) {
      message.error("Please enter a reason.");
      return;
    }

    const payload = {
      _id: selectedMemberId,
      status,
      reason,
    };

    MemberStatus(payload); // Dispatch action
    setStatus(null);
    setReason("");
    setIsReasonRequired(false);
    setSelectedMemberId(null);
  };

  const dataSource =
    MemberArray &&
    MemberArray?.map((data, index) => ({
      key: index,
      id: data?._id,
      firstname: data?.firstname,
      middlename: data?.middlename,
      lastname: data?.lastname,
      email: data?.email,
      mobileNumber1: data?.mobileNumber1,
      status: data?.status,
      memberNo: data?.memberNo,
    }));

  //console.log(dataSource, "tr--------------");
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 112,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 112,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Member Number",
      dataIndex: "memberNo",
      key: "memberNo",
      ...getColumnSearchProps("memberNo", "Member Number"),
    },
    {
      title: "First name",
      dataIndex: "firstname",
      key: "firstname",
      ...getColumnSearchProps("firstname", "First name"),
    },

    {
      title: "Middle name",
      dataIndex: "middlename",
      key: "middlename",
      ...getColumnSearchProps("middlename", "Middle name"),
    },
    {
      title: "Last name",
      dataIndex: "lastname",
      key: "lastname",
      ...getColumnSearchProps("lastname", "Last name"),
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email", "Email"),
    },

    {
      title: "Mobile",
      dataIndex: "mobileNumber1",
      key: "mobileNumber1",
      ...getColumnSearchProps("mobileNumber1", "Mobile"),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "INACTIVE", value: "INACTIVE" },
        { text: "CANCEL", value: "CANCEL" },
        { text: "DFCPAID", value: "DFCPAID" },
        { text: "DFCUNPAID", value: "DFCUNPAID" },
      ],
      onFilter: (value, record) => record?.status?.toUpperCase() === value,
      render: (text, row) => (
        <Space size="middle">
          <Tag
            color={
              (row?.status?.toUpperCase() === "ACTIVE" && "blue") ||
              (row?.status?.toUpperCase() === "INACTIVE" && "red") ||
              (row?.status?.toUpperCase() === "CANCEL" && "gray") ||
              (row?.status?.toUpperCase() === "DFCPAID" && "green") ||
              (row?.status?.toUpperCase() === "DFCUNPAID" && "orange")
            }
          >
            {row?.status}
          </Tag>
        </Space>
      ),
    },

    {
      title: "Operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Link to={`/memberdetail/${record?.id}`}>
              <Button type="primary" icon={<EyeOutlined />} />
            </Link>
          </Tooltip>

          <Tooltip title="Edit">
            <Link to={`/edit-member/${record?.id}`}>
              <Button
                style={{ background: "black", border: "black" }}
                type="primary"
                icon={<EditOutlined />}
              />
            </Link>
          </Tooltip>

          <Popconfirm
            title="Are you sure you want to Delete this user?"
            onConfirm={() => handleMemberDelete(record?.id)}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Tooltip title="Delete">
              <Button danger type="primary" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title={
              <>
                <p>Select Status:</p>
                <Radio.Group onChange={handleStatusChange} value={status}>
                  {record?.status !== "ACTIVE" && (
                    <Radio value="ACTIVE">Active</Radio>
                  )}
                  {record?.status !== "INACTIVE" && (
                    <Radio value="INACTIVE">Inactive</Radio>
                  )}
                  {record?.status !== "CANCEL" && (
                    <Radio value="CANCEL">Cancel</Radio>
                  )}
                </Radio.Group>

                {/* Show input field only for INACTIVE or CANCEL */}
                {(status === "INACTIVE" || status === "CANCEL") && (
                  <>
                    <p style={{ marginTop: 10 }}>Enter Reason:</p>
                    <Input
                      placeholder="Enter reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </>
                )}
              </>
            }
            onConfirm={handleConfirmStatusChange}
            okText="Submit"
            cancelText="Cancel"
            onOpenChange={(visible) => {
              if (visible) {
                setSelectedMemberId(record?.id);
              } else {
                setStatus(null);
                setReason("");
              }
            }}
          >
            <Tooltip title="Change Status">
              <Button
                type="primary"
                style={{
                  background: "#000",
                  border: "none",
                  color: "white",
                }}
                icon={<SyncOutlined />}
              ></Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <SiteLayout>
      {SelectLoader === true && <Loader />}
      <div
        style={{ padding: "30px" }}
        className="checkout-form-container gig-sure-card-wrapper"
      >
        <div className="wrapper_twoDiv">
          <Space style={{ marginBottom: 18 }}>
            <h3 className="start">Member List</h3>
          </Space>
          <div className="end">
            <Space className="end" style={{ marginBottom: 18 }}>
              <Link to={"/add-member/" + totalCount}>
                <Button className="primary custom-btn">Add Member</Button>
              </Link>
            </Space>
          </div>
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          onChange={(pagination) => {
            console.log("pagination: ", pagination);
            setCurrentPage(pagination.current);
            setPageSize(pagination.pageSize);
          }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalCount,
            showSizeChanger: true,
          }}
        />
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(Members);
