import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../Config/Api";
import {
  message,
  Spin,
  Card,
  Row,
  Col,
  PageHeader,
  Badge,
  Descriptions,
  Skeleton,
} from "antd";
import moment from "moment";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { LoadingOutlined } from "@ant-design/icons";
import { SiteConstants } from "../../Config/SiteConstants";

const TransactionDetails = () => {
  const { id } = useParams();
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { NA } = SiteConstants;

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (!id) {
        message.error("Transaction ID is undefined");
        setLoading(false);
        return;
      }

      try {
        const response = await api.get(
          `admin/getTransactionDetails?txnId=${id}`
        );
        console.log(response, "response");
        if (response.status === 200) {
          setTransactionDetails(response.data);
        } else {
          message.error("Failed to fetch transaction details");
        }
      } catch (error) {
        console.error("Error fetching transaction details:", error);
        message.error("An error occurred while fetching transaction details");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [id]);

  const formatDateToUTC = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  return (
    <SiteLayout>
      <div className="pgheader">
        <Row>
          <Col xs={24} xl={24}>
            <PageHeader
              onBack={() => window.history.back()}
              title="Transaction Details"
            />
          </Col>
        </Row>
      </div>

      <div style={{ padding: "10px" }}>
        <Row>
          <Col xs={24} xl={24}>
            <Card>
              <Skeleton active loading={loading}>
            


                {transactionDetails ? (
                  <div>
                      {transactionDetails.paymentDetails.status === "CANCELLED" && (
                        <div
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            fontSize: "2em",
                            fontWeight: "bold",
                            textAlign: "center",
                            textDecoration: "underline",
                          }}
                        >
                          {transactionDetails.paymentDetails.status}
                        </div>
                      )}

                    <h3
                      style={{
                        marginBottom: "8px",
                        fontWeight: "bold",
                        fontSize: "1.5em",
                      }}
                    >
                              <Badge
                        color="purple"
                        text={`DFC Number: ${transactionDetails.paymentDetails.dfcNumber}`}                       
                      />
                    </h3>
                    <h3
                      style={{
                        marginBottom: "8px",
                        fontWeight: "bold",
                        fontSize: "1.5em",
                      }}
                    >
                      <Badge
                        color="purple"
                        text={`Payment Date: ${formatDateToUTC(
                          transactionDetails.paymentDetails.paymentDate
                        )}`}
                      />
                    </h3>
                    <h3
                      style={{
                        marginBottom: "8px",
                        fontWeight: "bold",
                        fontSize: "1.5em",
                      }}
                    >
                      <Badge
                        color="purple"
                        text={`Receipt Date: ${formatDateToUTC(
                          transactionDetails.paymentDetails.receiptDate || "N|A"
                        )}`}
                      />
                    </h3>
                    <h3
                      style={{
                        marginBottom: "8px",
                        fontWeight: "bold",
                        fontSize: "1.5em",
                      }}
                    >
                      <Badge
                        color="purple"
                        text={`Group Id: ${transactionDetails.paymentDetails.internalGroupId}`}
                      />
                    </h3>

                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      {transactionDetails.paymentDetails.members.map(
                        (member, index) => (
                          <Descriptions
                            key={index}
                            title={`Member ${index + 1} - ${member.firstname} ${
                              member.middlename || ""
                            } ${member.lastname}`}
                            bordered
                            column={1}
                            style={{ marginBottom: "10px" }}
                          >
                            <Descriptions.Item label="Member Id">
                              <Badge
                                status="processing"
                                text={member.memberNo || "N/A"}
                              />
                            </Descriptions.Item>
                            <Descriptions.Item label="City">
                              <Badge
                                status="processing"
                                text={member.city || "N/A"}
                              />
                            </Descriptions.Item>
                            <Descriptions.Item label="Contribution">
                              <Badge
                                status="processing"
                                text={
                                  member.fees
                                    ? member.fees.toLocaleString("en-IN", {
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency: "INR",
                                      })
                                    : "₹0"
                                }
                              />
                            </Descriptions.Item>

                            <Descriptions.Item label="Late Payment Charge">
                              <Badge
                                status="processing"
                                text={
                                  member.lateFees
                                    ? member.lateFees.toLocaleString("en-IN", {
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency: "INR",
                                      })
                                    : "₹0"
                                }
                              />
                            </Descriptions.Item>

                            <Descriptions.Item label="Yearly Maintenance Charge">
                              <Badge
                                status="processing"
                                text={
                                  member.Maintaince_charge
                                    ? member.Maintaince_charge.toLocaleString(
                                        "en-IN",
                                        {
                                          maximumFractionDigits: 0,
                                          style: "currency",
                                          currency: "INR",
                                        }
                                      )
                                    : "₹0"
                                }
                              />
                            </Descriptions.Item>
                          </Descriptions>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <p>No transaction details found.</p>
                )}
              </Skeleton>
              {!loading && transactionDetails && (
                <Descriptions title="Payment Information" bordered>
                  <Descriptions.Item label="Payment Method" span={2}>
                    <Badge
                      status="processing"
                      text={`${transactionDetails.paymentDetails.paymentMethod}`}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Receipt No." span={2}>
                    <Badge
                      status="processing"
                      text={` ${transactionDetails.paymentDetails.receiptNumber}`}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Amount" span={2}>
                    <Badge
                      status="processing"
                      text={`${transactionDetails.paymentDetails.amount}`}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Bank Name" span={2}>
                    <Badge
                      status="processing"
                      text={
                        transactionDetails.paymentDetails.chequeDetails
                          ?.bankName
                          ? `${transactionDetails.paymentDetails.chequeDetails.bankName}`
                          : "N/A"
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Branch Name" span={2}>
                    <Badge
                      status="processing"
                      text={
                        transactionDetails.paymentDetails.chequeDetails
                          ?.branchName
                          ? `${transactionDetails.paymentDetails.chequeDetails.branchName}`
                          : "N/A"
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Cheque No." span={2}>
                    <Badge
                      status="processing"
                      text={
                        transactionDetails.paymentDetails.chequeDetails
                          ?.chequeNo
                          ? `${transactionDetails.paymentDetails.chequeDetails.chequeNo}`
                          : "N/A"
                      }
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Cheque Return Charge" span={2}>
                    <Badge
                      status="processing"
                      text={
                        transactionDetails.paymentDetails.chequeDetails
                          ?.chequeReturnCharge
                          ? `${transactionDetails.paymentDetails.chequeDetails.chequeReturnCharge}`
                          : "₹0"
                      }
                    />
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </SiteLayout>
  );
};

export default TransactionDetails;
