import React, { useEffect, useState, useRef } from "react";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../Members/style.css";
import api from "../../Config/Api";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Typography,
  message,
  PageHeader,
  Skeleton,
  Radio,
  DatePicker,
} from "antd";
import moment from "moment";

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
const { Title } = Typography;
// Action

const mapDispatch = (dispatch) => {
  return {
    TransactioEdit: (payload) => dispatch(TransactioEdit(payload)),
  };
};

const mapState = (props) => ({
  SelectLoader: props?.MemberReducer?.isloading,
  success: props?.MemberReducer?.success,
  successDetails: props?.MemberReducer?.successDetails,
  MemberDetails: props?.MemberReducer?.MemberDetails,
});

function TransactioEdit(store) {
  const formRef = useRef();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const txnId = useParams().txnId;
  const [loading, setLoading] = useState(false);
  const [dfcDetails, setDfcDetails] = useState(null);
  const [dfcNumber, setDfcNumber] = useState("");
  const [groupData, setGroupData] = useState("");
  const [userId, setUserId] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false); // Track API call success
  const [createdDate, setcreatedDate] = useState();
  const [combinedTotal, setCombinedTotal] = useState("");
  const [totalFees, setTotalFees] = useState(0);
  const [totalCharges, setTotalCharges] = useState(0);
   const [dfcLateMonth,setDfcLateMonth]=useState(0)
   const [dfcLateFees,setDfcLateFees]=useState(0)

  const [paymentMethod, setPaymentMethod] = useState("CHEQUE");
  const [cashDetails, setCashDetails] = useState({
    receiptNo: "",
    receiptDate: null, // Set default value to current date
  });

  const [chequeDetails, setChequeDetails] = useState({
    bankName: "",
    branchName: "",
    chequeNo: "",
    chequeDate: null,
    chequeReturnCharge: 0,
    receiptDate: null,
  });

  const handleSubmit = async () => {
    if (!dfcNumber) {
      message.error("DFC number is required");
      return;
    }

    if (!/^\d+$/.test(dfcNumber)) {
      message.error("Please insert a valid DFC number");
      return;
    }

    setLoading(true);

    try {
      const response = await api.get(
        `admin/fetchDfcDetails?dfcNumber=${dfcNumber}`
      );

      if (response.status === 200) {
        setDfcDetails(response.data.data);
        message.success("Data fetched successfully");
      } else {
        message.error(response.data.message || "Failed to fetch details");
      }
    } catch (error) {
      message.error(
        error.response?.data?.message || "Please insert valid Dfc No."
      );
    } finally {
      setLoading(false);
    }
  };

  const updateTotals = (updatedMembers, updatedChequeDetails) => {
    let newFees = 0;
    let newLateFees = 0;
    let newMaintaince_charge = 0;
    const newChequeReturnCharges = parseFloat(
      updatedChequeDetails?.chequeReturnCharge || 0
    );

    updatedMembers.forEach(({ fees, lateFees, Maintaince_charge, checked }) => {
      if (checked) {
        newFees += parseFloat(fees) || 0;
        newMaintaince_charge += parseFloat(Maintaince_charge) || 0;
        newLateFees += lateFees || 0;
      }
    });


    setTotalFees(newFees);
    setTotalCharges(newFees + newLateFees + newChequeReturnCharges);
    setCombinedTotal(
      newFees + newLateFees + newMaintaince_charge + newChequeReturnCharges
    );
  };

  const handleDateChange = (date, type) => {
    if (type === "RECEIPT") {
      const formattedDate = date ? date.format("YYYY-MM-DD") : null;
  
      setChequeDetails((prevDetails) => {
        const updatedDetails = { ...prevDetails, receiptDate: formattedDate };
  
        const updatedMembersList = members.map((member) => {
          if (member.checked) {
            const createdMoment = moment(createdDate, "YYYY-MM-DD");
            const receiptMoment = moment(formattedDate, "YYYY-MM-DD");
  
            if (!createdMoment.isValid() || !receiptMoment.isValid()) {
              return member; 
            }
  
            // Calculate the difference in months
            const monthsDifference = receiptMoment.diff(createdMoment, "months", true);
            // console.log(`Months Difference for ${member.memberNo}:`, monthsDifference);
  
            // Apply late fees if the difference exceeds dfcLateMonth
            const newLateFees = monthsDifference > dfcLateMonth ? dfcLateFees : 0;
            // console.log(`Late Fee Before: ${member.lateFees}, Late Fee After: ${newLateFees}`);
  
            return { ...member, lateFees: newLateFees };
          }
          return member;
        });
  
        setMembers(updatedMembersList);
  
        setTimeout(() => {
          updateTotals(updatedMembersList, updatedDetails);
        }, 0);
  
        return updatedDetails;
      });
    }
  };

  const handleInputChange = (index, field) => (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index][field] = value; // Update the specific field

      updateTotals(updatedMembers, chequeDetails); // Ensure receiptDate is included
      return updatedMembers;
    });
  };

  const handleCheckboxChange = (index) => {
    setMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index].checked = !updatedMembers[index].checked; // Toggle checkbox state

      updateTotals(updatedMembers, chequeDetails); // Ensure receiptDate is included
      return updatedMembers;
    });
  };

  useEffect(() => {
    updateTotals(members, chequeDetails);
  }, [members, chequeDetails]);

  const handleChequeChange = (e) => {
    const { name, value } = e.target;
    const numericFields = ["chequeNo", "chequeReturnCharge"];

    const updatedValue = numericFields.includes(name)
      ? parseFloat(value) || 0
      : value;

    const updatedDetails = { ...chequeDetails, [name]: updatedValue };
    setChequeDetails(updatedDetails);

    updateTotals(members, updatedDetails);
  };

  useEffect(() => {
    updateTotals(members, chequeDetails);
  }, [members, chequeDetails]);

  function handleFormSubmit1(params) {
    // pass payload to action and reducer
    let payload = {
      memberdetails: [
        {
          memberId1: params?.MemberId1,
          fees1: params?.Fees1,
          charges1: params?.Charges1,
        },
        {
          memberId2: params?.MemberId2,
          fees2: params?.Fees2,
          charges2: params?.Charges2,
        },
      ],
      payment: params?.PaymentMethod,
      receiptno: params?.ReceiptNo,
      receiptdate: params?.ReceiptDate,
    };

    if (params?.Group) {
      payload["group"] = params?.Group;
    }

    if (params?.FirstMember) {
      payload["firstmember"] = params?.FirstMember;
    }

    if (params?.PaymentMethod === "CHEQUE") {
      payload["chequedetail"] = {
        bank: params?.BankName,
        branch: params?.BranchName,
        cheque: params?.ChequeNo,
        chequedate: params?.ChequeDate,
      };
    }
    store.addFess(payload);
  }

  const onFinishFailed = (errorInfo) => {};

  const fetchGroupData1 = async () => {
    if (!groupData) {
      message.error("Please enter a Group ID");
      return;
    }
    if (!dfcNumber) {
      message.error("Please enter a DFC number");
      return;
    }

    try {
      const response = await api.get("/admin/fetchGroupDetails", {
        params: { internalGroupId: groupData, dfcNumber: dfcNumber },
      });

      if (response.data.success) {
        if (
          response.data.message ===
          "All members have already paid their DFC fees."
        ) {
          message.success(response.data.message);
          setMembers([]);
          setIsDataFetched(false);
          return;
        }

        const fetchedMembers = response.data.data.members;

        if (Array.isArray(fetchedMembers) && fetchedMembers.length > 0) {
          const memberDetailsArray = fetchedMembers.map((member) => ({
            _id: member._id,
            memberNo: member.memberNo,
            memberName: member.memberName,
            city: member.city,
            fees: 300,
            lateFees: member.lateFees || 0,
            Maintaince_charge: member.Maintaince_charge || 0,
            checked: true, // Default checked
          }));

          setMembers(memberDetailsArray);
          setIsDataFetched(true);

          setMembers(memberDetailsArray);
          setIsDataFetched(true);
          message.success("Data fetched successfully");

          // Ensure totals update after members are set
          setTimeout(() => {
            updateTotals(memberDetailsArray, chequeDetails);
          }, 0);
        } else {
          message.error("No members found in the group data.");
          setIsDataFetched(false);
        }
      } else {
        message.error("Failed to fetch group data. Please try again.");
        setIsDataFetched(false);
      }
    } catch (error) {
      console.error("API error:", error);
      message.error("Failed to fetch group data. Please try again.");
      setIsDataFetched(false);
    }
  };

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (!txnId) {
        message.error("Transaction ID is undefined");
        return;
      }
  
      try {
        setLoading(true);
  
        const response = await api.get(`admin/getTransactionDetails?txnId=${txnId}`);
        if (response.status === 200) {
          const transactionDetails = response.data.paymentDetails;
  
          // Fetch and set DFC details
          setDfcNumber(transactionDetails.dfcNumber || "");
          setcreatedDate(transactionDetails.createdDate || "");
  
          // Fetch and set payment method
          setPaymentMethod(transactionDetails.paymentMethod);
          setDfcLateMonth(transactionDetails.dfcLateMonth || 0);
          setDfcLateFees(transactionDetails.dfcLateFees || 0);
  
          const members = transactionDetails.members || [];
          const totalFees = members.reduce(
            (sum, member) =>
              sum +
              (member.fees || 0) +
              (member.lateFees || 0) +
              (member.Maintaince_charge || 0),
            0
          );
  
          // Set form fields
          form.setFieldsValue({
            internalGroupId: transactionDetails.internalGroupId,
            members: members.map((member) => ({
              _id: member._id,
              memberId: member._id,
              memberNo: member.memberNo,
              memberName: `${member.firstname} ${member.middlename || ""} ${member.lastname}`.trim(),
              city: member.city,
              fees: member.fees,
              lateFees: member.lateFees,
              Maintaince_charge: member.Maintaince_charge,
            })),
            receiptNo: transactionDetails.receiptNumber || "",
            amount: totalFees,
          });
  
          let receiptDate = null;
  
          if (transactionDetails.paymentMethod === "CHEQUE") {
            const chequeDetails = transactionDetails.chequeDetails || {};
            receiptDate = chequeDetails.receiptDate ? moment(chequeDetails.receiptDate) : null;
  
            setChequeDetails({
              bankName: chequeDetails.bankName || "",
              branchName: chequeDetails.branchName || "",
              chequeNo: chequeDetails.chequeNo || "",
              chequeDate: chequeDetails.chequeDate ? moment(chequeDetails.chequeDate) : null,
              chequeReturnCharge: chequeDetails.chequeReturnCharge || 0,
              receiptDate: receiptDate,
            });
          } else if (transactionDetails.paymentMethod === "CASH") {
            const cashDetails = transactionDetails.cashDetails || {};
            receiptDate = cashDetails.receiptDate ? moment(cashDetails.receiptDate) : null;
  
            setCashDetails({
              receiptNo: cashDetails.receiptNo || "",
              receiptDate: receiptDate,
            });
          }
  
          // Compare receiptDate and createdDate
          if (transactionDetails.createdDate && receiptDate) {
            const createdMoment = moment(transactionDetails.createdDate);
            const receiptMoment = moment(receiptDate);
            const daysDifference = receiptMoment.diff(createdMoment, "days");
  
          }
  
          setMembers(
            members.map((member) => ({
              _id: member._id,
              memberId: member._id,
              memberNo: member.memberNo,
              memberName: `${member.firstname} ${member.middlename || ""} ${member.lastname}`.trim(),
              city: member.city,
              fees: member.fees,
              lateFees: member.lateFees,
              Maintaince_charge: member.Maintaince_charge,
              checked: true,
            }))
          );
        } else {
          message.error("Failed to fetch transaction details");
        }
      } catch (error) {
        message.error("An error occurred while fetching transaction details");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchTransactionDetails();
  }, [txnId, form]);
  
  const handleEditSubmit = async () => {
    try {
      // Filter only selected members
      const selectedMembers = members.filter((member) => member.checked);

      const membersFees = selectedMembers.map((member) => ({
        memberId: member.memberId || member._id,
        _id: member._id,
        memberNo: member.memberNo,
        memberName: member.memberName,
        city: member.city,
        fees: Number(member.fees) || 0,
        lateFees: Number(member.lateFees) || 0,
        Maintaince_charge: Number(member.Maintaince_charge) || 0,
        totalFee:
          Number(member.fees || 0) +
          Number(member.lateFees || 0) +
          Number(member.Maintaince_charge || 0),
      }));

      // Calculate members' total fees
      const membersTotalFees = membersFees.reduce(
        (total, member) => total + member.totalFee,
        0
      );

      const totalAmount = membersTotalFees;

      const formattedReceiptDate =
        cashDetails?.receiptDate || chequeDetails?.receiptDate
          ? moment(
              cashDetails?.receiptDate || chequeDetails?.receiptDate
            ).toISOString()
          : "";

      const payload = {
        txnId: txnId,
        groupId: groupData,
        amount: combinedTotal,
        paymentMethod: paymentMethod,
        userId: userId,
        dfcNumber: dfcNumber,
        membersFees: membersFees, // Only selected members
        receiptNumber: cashDetails?.receiptNo || chequeDetails?.receiptNumber,
        receiptDate: formattedReceiptDate,
        paymentDetails: {
          userId: userId,
          paymentUserIds: membersFees.map((member) => member.memberId),
          dfcNumber: dfcNumber,
          internalGroupId: groupData,
          members: membersFees,
          paymentDate: moment().toISOString(),
          paymentMethod: paymentMethod,
          amount: totalAmount,
          ...(paymentMethod === "CHEQUE" && {
            chequeDetails: chequeDetails || {},
          }),
          ...(paymentMethod === "CASH" && { cashDetails: cashDetails || null }),
        },
      };

      const response = await api.put(
        `admin/editTransaction?txnId=${txnId}`,
        payload
      );

      if (response.status === 200) {
        message.success("Transaction updated successfully");
        navigate("/transactions");
      } else {
        message.error(`Failed to submit: ${response.data.message}`);
      }
    } catch (error) {
      message.error(
        `An error occurred while submitting the transaction details. ${error.message}`
      );
    }
  };

  return (
    <SiteLayout>
      <div className="pgheader">
        <Row>
          <Col xs={24} xl={24}>
            <PageHeader
              onBack={() => window.history.back()}
              title="Edit Transaction Details"
            />
          </Col>
        </Row>
      </div>
      <Skeleton active loading={loading}>
        <div className="subscription-container center-content">
          <Row gutter={2}>
            <Col
              xxl={20}
              xl={20}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{ margin: "0 auto" }}
            >
              <div
                style={{ padding: "30px" }}
                className="checkout-form-container gig-sure-card-wrapper"
              >
                <Form
                  scrollToFirstError={true}
                  onFinish={handleFormSubmit1}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  form={form}
                  name="dfc"
                  autoComplete="off"
                  ref={formRef}
                ></Form>
                <Box component="form" sx={{ mt: 3 }}>
                  <Row gutter={10}>
                    <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                      <Row gutter={10} align="middle">
                        <Col span={16}>
                          <Form.Item
                            label="DFC No"
                            name="dfcNumber"
                            rules={[
                              {
                                pattern: /^(?:\d*)$/,
                                message: "Please insert a valid DFC No",
                              },
                            ]}
                          >
                            <Input
                              className="form-input-border"
                              autoComplete="off"
                              placeholder={dfcNumber || "Enter DFC Number"} // Dynamically show DFC No in placeholder
                              value={dfcNumber}
                              onChange={(e) => setDfcNumber(e.target.value)} // Update dfcNumber state on input change
                              disabled
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Button
                            // type="primary"
                            variant="contained"
                            color="primary"
                            className="btn"
                            disabled
                            onClick={handleSubmit}
                            sx={{ width: "100%", py: 1, height: "40px" }}
                          >
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Search"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {dfcDetails && (
                    <Box mt={1}>
                      <Typography variant="h6" mb={2}>
                        DFC Details:
                      </Typography>
                      {dfcDetails.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Member No</TableCell>
                                <TableCell>Member Name</TableCell>
                                <TableCell>Date of Death</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {dfcDetails.map((detail, index) => (
                                <TableRow key={index}>
                                  <TableCell>{detail.memberNo}</TableCell>
                                  <TableCell>{detail.memberName}</TableCell>
                                  <TableCell>
                                    {new Date(
                                      detail.dateOfDeath
                                    ).toLocaleDateString()}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography variant="body2">
                          No details available for this DFC number.
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>

                <Form form={form} layout="vertical">
                  <Row gutter={10}>
                    <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                      <Row gutter={10} align="middle">
                        <Col span={16}>
                          <Form.Item
                            label="Group #"
                            name="internalGroupId"
                            rules={[
                              {
                                pattern: /^(?:\d*)$/,
                                message: "Please insert a valid Group ID",
                              },
                            ]}
                          >
                            <Input
                              className="form-input-border"
                              autoComplete="off"
                              placeholder="Group ID"
                              value={groupData}
                              onChange={(e) => setGroupData(e.target.value)}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Button
                            variant="contained"
                            color="primary"
                            className="btn"
                            onClick={fetchGroupData1}
                            sx={{ width: "100%", py: 1, height: "40px" }}
                            disabled
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {members.length > 0 &&
                    members.map((member, index) => (
                      <div
                        style={{
                          padding: "20px",
                          background: "#f9f9f9",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          key={index}
                          style={{
                            border: "1px solid #ddd",
                            padding: "15px",
                            borderRadius: "8px",
                            marginBottom: "10px",
                            background: "#fff",
                          }}
                        >
                          <Row gutter={10} key={index} align="middle">
                            {/* Show checkbox only if data has been fetched */}
                            <Col xl={2} lg={3} md={4} sm={6} xs={6}>
                              <Form.Item>
                                <Checkbox
                                  checked={member.checked}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                              </Form.Item>
                            </Col>

                            {/* Member Id */}
                            <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                              <Form.Item
                                label="Member Id #"
                                className="field-with-prefix"
                              >
                                <Input
                                  placeholder="Member Id"
                                  disabled
                                  value={member.memberNo}
                                />
                              </Form.Item>
                            </Col>

                            {/* Member Name */}
                            <Col xl={8} lg={12} md={16} sm={24} xs={24}>
                              <Form.Item
                                label="Member #"
                                className="field-with-prefix"
                              >
                                <Input
                                  placeholder="Member"
                                  disabled
                                  value={member.memberName}
                                />
                              </Form.Item>
                            </Col>

                            {/* City */}
                            <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                              <Form.Item
                                label="City #"
                                className="field-with-prefix"
                              >
                                <Input
                                  placeholder="City"
                                  disabled
                                  value={member.city}
                                />
                              </Form.Item>
                            </Col>

                            {/* Fees */}
                            <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                              <Form.Item
                                label="Contribution "
                                className="field-with-prefix"
                              >
                                <Input
                                  value={member.fees}
                                  onChange={handleInputChange(index, "fees")}
                                />
                              </Form.Item>
                            </Col>

                        {/* Late Fees */}
                            <Col
                              xl={4}
                              lg={12}
                              md={8}
                              sm={24}
                              xs={24}
                              style={{ marginLeft: "73px" }}
                            >
                              <Form.Item
                                label="Late Payment Charge"
                                className="field-with-prefix"
                              >
                                <Input
                                  value={members[0]?.lateFees || 0}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>

                            {/* Maintenance Charge */}
                            <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                              <Form.Item
                                label="Maintenance Charge"
                                className="field-with-prefix"
                              >
                                <Input
                                  placeholder="Maintenance Charge"
                                  value={member.Maintaince_charge || 0}
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}

                  {/* Display Combined Total */}
                  <div style={{ marginTop: "20px" }}>
                    <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                      <Form.Item label="Combined Total">
                        <Input
                          disabled
                          value={combinedTotal}
                          style={{ color: "#808080" }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                </Form>

                <Form layout="vertical">
                  {/* Payment Method Radio Buttons */}
                  <Row className="mt-1">
                    <Col span={24}>
                      <Title level={4}>Payment</Title>
                    </Col>
                  </Row>

                  <Form.Item
                    name="PaymentMethod"
                    label="Payment Method"
                    initialValue={paymentMethod}
                  >
                    <Radio.Group
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <Radio value="CASH">Cash</Radio>
                      <Radio value="CHEQUE">Cheque</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {/* Input for Cheque Payment */}
                  {paymentMethod === "CHEQUE" && (
                    <>
                      {/* First Row: 4 items */}
                      <Row gutter={10}>
                        <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Bank Name"
                            rules={[
                              {
                                required: true,
                                message: "Bank Name is required",
                              },
                            ]}
                          >
                            <Input
                              value={chequeDetails.bankName}
                              name="bankName"
                              onChange={handleChequeChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Branch Name"
                            rules={[
                              {
                                required: true,
                                message: "Branch Name is required",
                              },
                            ]}
                          >
                            <Input
                              value={chequeDetails.branchName}
                              name="branchName"
                              onChange={handleChequeChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Cheque Number"
                            rules={[
                              {
                                required: true,
                                message: "Cheque Number is required",
                              },
                            ]}
                          >
                            <Input
                              value={chequeDetails.chequeNo}
                              name="chequeNo"
                              onChange={handleChequeChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Cheque Date"
                            rules={[
                              {
                                required: true,
                                message: "Cheque Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              value={
                                chequeDetails.chequeDate
                                  ? moment(
                                      chequeDetails.chequeDate,
                                      "YYYY-MM-DD"
                                    ) // Parse stored date correctly
                                  : null
                              }
                              format="DD-MM-YYYY" // Display format
                              onChange={(date) =>
                                handleDateChange(date, "CHEQUE")
                              }
                              style={{ width: "100%" }}
                              disabledDate={(current) =>
                                current && current > moment().endOf("day")
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* Second Row: Additional items */}
                      <Row gutter={10}>
                        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                          <Form.Item label="Cheque Return Charge">
                            <Input
                              value={chequeDetails.chequeReturnCharge}
                              name="chequeReturnCharge"
                              onChange={handleChequeChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                          <Form.Item label="Receipt Number">
                            <Input
                              value={form.getFieldValue("receiptNo") || "N/A"}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        {/* Receipt Date */}
                        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                          <Form.Item
                            label="Receipt Date"
                            rules={[
                              {
                                required: true,
                                message: "Receipt Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="Receipt date"
                              style={{ width: "100%" }}
                              format="DD-MM-YYYY"
                              value={
                                chequeDetails.receiptDate
                                  ? moment(
                                      chequeDetails.receiptDate,
                                      "YYYY-MM-DD"
                                    )
                                  : null
                              }
                              onChange={(date) =>
                                handleDateChange(date, "RECEIPT")
                              }
                              disabledDate={(current) =>
                                current && current > moment().endOf("day")
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}

                  {paymentMethod === "CASH" && (
                    <Row gutter={10}>
                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item label="Receipt Number">
                          <Input
                            value={form.getFieldValue("receiptNo") || "N/A"}
                            disabled
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          label="Receipt Date"
                          rules={[
                            {
                              required: true,
                              message: "Receipt Date is required",
                            },
                          ]}
                        >
                          <DatePicker
                            placeholder="Receipt date"
                            style={{ width: "100%" }}
                            format="YYYY-MM-DD" // Set the format you want for display
                            value={
                              cashDetails.receiptDate
                                ? moment(cashDetails.receiptDate).isValid()
                                  ? moment(cashDetails.receiptDate) // Ensure moment object is valid
                                  : null
                                : null
                            }
                            onChange={(date) => handleDateChange(date, "CASH")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {/* Submit Button */}
                  <div style={{ textAlign: "left", marginTop: "20px" }}>
                    <Button type="primary" onClick={handleEditSubmit}>
                      Update Transaction
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Skeleton>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(TransactioEdit);
