import { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "MuktaVaaniBold",
  src: "/fonts/MuktaVaani-Bold.ttf",
});
Font.register({
  family: "MuktaVaaniRegular",
  src: "/fonts/MuktaVaani-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "MuktaVaaniRegular",
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 3,
  },
  logo: {
    width: 75,
    height: 75,
  },
  logo2: {
    width: 100,
    height: 100,
  },
  headerTextContainer: {
    flex: 1,
    textAlign: "center",
  },
  headerTextSmall: {
    fontSize: 12,
  },
  headerTextLarge: {
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "MuktaVaaniBold",
  },
  headerTextMedium: {
    fontSize: 12,
    marginTop: 1,
    fontFamily: "MuktaVaaniBold",
  },
  horizontalLine: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 5,
  },
  filters: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 12,
  },
  totalsContainer: {
    marginTop: 15,
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: "#000",
  },
  totalsText: {
    fontSize: 11,
    fontWeight: "bold",
    marginVertical: 3,
  },
  tableContainer: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 5,
    overflow: "hidden",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#ddd",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    fontFamily: "MuktaVaaniBold",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  column: {
    fontSize: 9,
    textAlign: "center",
    padding: 4,
    borderRightWidth: 0.4,
    borderRightColor: "#000",
    borderLeftWidth: 0.4,
    borderLeftColor: "#000",
  },
  lastColumn: {
    borderRightWidth: 0.4,
    borderRightColor: "#000",
  },
  columnWidths: {
    userMemberNo: "7%",
    dfcNumber: "7%",
    userName: "10%",
    userCity: "10%",
    userMobile: "12%",
    receiptNumber: "7%",
    receiptDate: "11%",
    bankName: "9%",
    branchName: "9%",
    chequeNo: "8%",
    paymentMethod: "9%",
    amount: "8%",
    lateFees: "8%",
    chequeReturnCharge: "8%",
  },
  totalsTableContainer: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 5,
    overflow: "hidden",
    width: "100%",
  },
  totalsTableHeader: {
    flexDirection: "row",
    backgroundColor: "#ddd",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    fontFamily: "MuktaVaaniBold",
  },
  totalsTableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  totalsTableFooter: {
    flexDirection: "row",
    backgroundColor: "#f5f5f5",
    borderTopWidth: 1,
    borderTopColor: "#000",
  },
  totalsColumn: {
    flex: 1,
    fontSize: 10,
    textAlign: "center",
    padding: 6,
    borderRightWidth: 1,
    borderRightColor: "#000",
    fontFamily: "MuktaVaaniBold",
  },
  headerColumn: {
    fontWeight: "bold",
    fontFamily: "MuktaVaaniBold",
  },
  footerColumn: {
    fontSize: 12,
    fontWeight: "bold",
    backgroundColor: "#ddd",
  },
  filtersContainer: {
    backgroundColor: "#f9f9f9",
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 8,
    marginVertical: 10,
    width: "100%",
    fontFamily: "MuktaVaaniBold",
  },
  filtersTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 4,
    color: "#333",
  },
  filterItem: {
    fontSize: 11,
    color: "#555",
    marginBottom: 2,
  },
});

const ReportPDF = ({ data, appliedFilters }) => {
  // Calculate totals
  const totalFees = data.reduce((sum, item) => sum + (item.fees || 0), 0);
  const totalLateFees = data.reduce(
    (sum, item) => sum + (item.lateFees || 0),
    0
  );
  const totalChequeReturnCharge = data.reduce(
    (sum, item) => sum + (item.chequeReturnCharge || 0),
    0
  );
  
  const grandTotal =
    totalFees +
    totalLateFees +
    totalChequeReturnCharge 
    ;

  const [reportDate, setReportDate] = useState("");

  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); 
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    setReportDate(getFormattedDate());
  }, []);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header with Logos and Text */}
        <View style={styles.headerContainer}>
          {/* Left Logo */}
          <Image src="/images/logo.png" style={styles.logo} />

          {/* Center Header Text */}
          <View style={styles.headerTextContainer}>
            <Text style={styles.headerTextSmall}>|| શ્રી ગણેશાય નમઃ ||</Text>
            <Text style={styles.headerTextLarge}>
             શ્રી સમસ્ત દશાશ્રીમાળી સુખડીયા સુરક્ષા ટ્રસ્ટ
            </Text>
            <Text style={styles.headerTextMedium}>
              રજી.નં.એ-૩૮૧૨, ૨જી.તા.૨૪-૫-૨૦૦૭
            </Text>
            <Text style={styles.headerTextMedium}>
              Trust Address: સેલર/9, મહાવીર ટાવર, એચ.પી. પેટ્રોલ પંપ, મહાલક્ષ્મી
              ચાર રસ્તા પાસે,
            </Text>
            <Text style={styles.headerTextMedium}>પાલડી, અમદાવાદ - 380007</Text>
          </View>

          {/* Right Logo */}
          <Image src="/images/logo3.png" style={styles.logo2} />
        </View>

        <View style={styles.horizontalLine} />

        {/* Applied Filters */}
        {(appliedFilters.dfcNumber ||
          appliedFilters.dateRange ||
          appliedFilters.receiptRange ||
          appliedFilters.chequeNumber ||
          reportDate) && (
          <View style={styles.filtersContainer}>
            {/* Report Date */}
            <Text style={styles.filterItem}>Report Date : {reportDate}</Text>

            {appliedFilters.dfcNumber && (
              <Text style={styles.filterItem}>
                DFC Number : {appliedFilters.dfcNumber}
              </Text>
            )}
            {appliedFilters.dateRange && (
              <Text style={styles.filterItem}>
                Date Range : {appliedFilters.dateRange}
              </Text>
            )}
            {appliedFilters.receiptRange && (
              <Text style={styles.filterItem}>
                Receipt No Range : {appliedFilters.receiptRange}
              </Text>
            )}
                {appliedFilters.chequeNumber && (
              <Text style={styles.filterItem}>
                Cheque Search : {appliedFilters.chequeNumber}
              </Text>
            )}
          </View>
        )}

        {/* Members Table Header */}
        <View style={styles.tableContainer}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text
              style={[
                styles.column,
                { width: styles.columnWidths.userMemberNo },
              ]}
            >
              Member No
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.dfcNumber }]}
            >
              DFC No
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.userName }]}
            >
              Name
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.userCity }]}
            >
              City
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.userMobile }]}
            >
              Mobile No.
            </Text>
            <Text
              style={[
                styles.column,
                { width: styles.columnWidths.receiptNumber },
              ]}
            >
              Receipt No
            </Text>
            <Text
              style={[
                styles.column,
                { width: styles.columnWidths.receiptDate },
              ]}
            >
              Receipt Date
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.bankName }]}
            >
              Bank Name
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.branchName }]}
            >
              Branch Name
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.chequeNo }]}
            >
              Cheque No
            </Text>
            <Text
              style={[
                styles.column,
                { width: styles.columnWidths.paymentMethod },
              ]}
            >
              Payment Method
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.amount }]}
            >
              Amount
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.lateFees }]}
            >
              Late Fees
            </Text>
            <Text
              style={[
                styles.column,
                { width: styles.columnWidths.chequeReturnCharge },
                styles.lastColumn,
              ]}
            >
              Cheque Re. Ch.
            </Text>
          </View>

          {/* Table Data */}
          {data.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text
                style={[
                  styles.column,
                  { width: styles.columnWidths.userMemberNo },
                ]}
              >
                {item.userMemberNo || "N/A"}
              </Text>
              <Text
                style={[
                  styles.column,
                  { width: styles.columnWidths.dfcNumber },
                ]}
              >
                {item.dfcNumber || "N/A"}
              </Text>
              <Text
                style={[styles.column, { width: styles.columnWidths.userName }]}
              >
                {item.userName || "N/A"}
              </Text>
              <Text
                style={[styles.column, { width: styles.columnWidths.userCity }]}
              >
                {item.userCity || "N/A"}
              </Text>
              <Text
                style={[
                  styles.column,
                  { width: styles.columnWidths.userMobile },
                ]}
              >
                {item.userMobile || "N/A"}
              </Text>
              <Text
                style={[
                  styles.column,
                  { width: styles.columnWidths.receiptNumber },
                ]}
              >
                {item.receiptNumber || "N/A"}
              </Text>
              <Text
                style={[
                  styles.column,
                  { width: styles.columnWidths.receiptDate },
                ]}
              >
                {item.receiptDate || "N/A"}
              </Text>
              <Text
                style={[styles.column, { width: styles.columnWidths.bankName }]}
              >
                {item.bankName || "N/A"}
              </Text>
              <Text
                style={[
                  styles.column,
                  { width: styles.columnWidths.branchName },
                ]}
              >
                {item.branchName || "N/A"}
              </Text>
              <Text
                style={[styles.column, { width: styles.columnWidths.chequeNo }]}
              >
                {item.chequeNo || "N/A"}
              </Text>
              <Text
                style={[
                  styles.column,
                  { width: styles.columnWidths.paymentMethod },
                ]}
              >
                {item.paymentMethod || "N/A"}
              </Text>
              <Text
                style={[styles.column, { width: styles.columnWidths.amount }]}
              >{`${item.fees?.toFixed(2) || "0.00"}`}</Text>
              <Text
                style={[styles.column, { width: styles.columnWidths.lateFees }]}
              >{`${item.lateFees?.toFixed(2) || "0.00"}`}</Text>
              <Text
                style={[
                  styles.column,
                  { width: styles.columnWidths.chequeReturnCharge },
                  styles.lastColumn,
                ]}
              >{`${item.chequeReturnCharge?.toFixed(2) || "0.00"}`}</Text>
            </View>
          ))}
        </View>

        <View style={styles.totalsTableContainer}>
          {/* Table Header */}
          <View style={styles.totalsTableHeader}>
            <Text style={[styles.totalsColumn, styles.headerColumn]}>
              Summary
            </Text>
            <Text style={[styles.totalsColumn, styles.headerColumn]}>
              Amount (₹)
            </Text>
          </View>

          {/* Table Rows */}
          <View style={styles.totalsTableRow}>
            <Text style={styles.totalsColumn}>Total Records</Text>
            <Text style={styles.totalsColumn}>{data.length}</Text>
          </View>

          <View style={styles.totalsTableRow}>
            <Text style={styles.totalsColumn}>Total Fees</Text>
            <Text style={styles.totalsColumn}>{totalFees.toFixed(2)}</Text>
          </View>

          <View style={styles.totalsTableRow}>
            <Text style={styles.totalsColumn}>Total Late Fees</Text>
            <Text style={styles.totalsColumn}>{totalLateFees.toFixed(2)}</Text>
          </View>

          <View style={styles.totalsTableRow}>
            <Text style={styles.totalsColumn}>Total Cheque Return Charge</Text>
            <Text style={styles.totalsColumn}>
              {totalChequeReturnCharge.toFixed(2)}
            </Text>
          </View>

          {/* Grand Total Row */}
          <View style={styles.totalsTableFooter}>
            <Text style={[styles.totalsColumn, styles.footerColumn]}>
              Grand Total
            </Text>
            <Text style={[styles.totalsColumn, styles.footerColumn]}>
              {grandTotal.toFixed(2)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ReportPDF;
