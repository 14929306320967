import {
  TRANSACTION_LIST,
  TRANSACTION_LOADING,
  INITIALIZE_STATE,
} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";
import moment from 'moment';

export const TransactionListAction = () => {
  return async (dispatch) => {
    dispatch(loader(true));
    try {
      const response = await api({
        url: "admin/transactions",
        method: "get",
      });

      if (response?.data?.data?.transactionsList) {
        let data = response.data.data.transactionsList.map((transaction) => {
          const dfcNumber = transaction?.dfcId?.length
            ? transaction.dfcId.map((dfc) => dfc?.dfcNumber).join(", ")
            : "N/A";

          const user = transaction?.userId || {};
          const userName = `${user.firstname || ""} ${user.lastname || ""}`.trim();
          const userMobile = user?.mobileNumber1 || "N/A";
          const userEmail = user?.originalEmail || "N/A";

          const paymentDetails = transaction?.paymentDetails?.length
            ? transaction.paymentDetails.map((detail) => {
                const chequeDate = detail?.chequeDate && moment(detail.chequeDate).isValid()
                  ? moment(detail.chequeDate).format("DD/MM/YYYY")
                  : "N/A";

                const receiptDate = detail?.receiptDate && moment(detail.receiptDate).isValid()
                  ? moment(detail.receiptDate).format("DD/MM/YYYY")
                  : "N/A";

                return {
                  type: detail?.type || "N/A",
                  bankName: detail?.bankName || "N/A",
                  branchName: detail?.branchName || "N/A",
                  chequeNo: detail?.chequeNo || "N/A",
                  chequeDate,
                  receiptDate,
                };
              })
            : [];

          const membersFees = transaction?.membersFees?.length
            ? transaction.membersFees.map((fee) => ({
                fees: fee?.fees || 0,
                lateFees: fee?.lateFees || 0,
                maintenanceCharge: fee?.Maintaince_charge || 0,
                memberId: fee?.memberId || "N/A",
              }))
            : [];

          return {
            _id: transaction?._id || "N/A", 
            txnId: transaction.txnId || "N/A",
            amount: transaction.amount || 0,
            lateFees: transaction.lateFees || 0,
            status: transaction.status || "N/A",
            paymentMethod: transaction.paymentMethod || "N/A",
            paymentDate: transaction.paymentDate && moment(transaction.paymentDate).isValid()
              ? moment(transaction.paymentDate).format("DD/MM/YYYY")
              : "N/A",
            dfcNumber,
            userName,
            userMobile,
            userEmail,
            paymentDetails,
            membersFees,
            receiptNumber: transaction.receiptNumber || "N/A",
          };
        });

        let responseStatus = response.data.code;
        if (responseStatus === 200) {
          dispatch({
            type: TRANSACTION_LIST,
            payload: data,
          });
        }
      } else {
        console.error("Invalid response structure:", response.data);
        message.error("Invalid data format received");
      }

      dispatch(loader(false));
    } catch (err) {
      errorMessageConfig(dispatch);
      console.error("Error fetching transactions:", err);
    }
  };
};

// Initialize State
export const initializeState = () => {
  return (dispatch) => {
    dispatch({
      type: INITIALIZE_STATE,
    });
  };
};

//  Error Handler
function errorMessageConfig(dispatch) {
  dispatch(loader(false));
  message.error("Something Went Wrong");
}

// Loader
export const loader = (para) => {
  return {
    type: TRANSACTION_LOADING,
    payload: para,
  };
};
