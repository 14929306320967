import React, { useState, useRef, useEffect } from "react";
import { Table, message, Space, Button, Input, Tooltip, Tag, Popconfirm ,DatePicker,Card,Select} from "antd";
import { Link } from "react-router-dom";
import Loader from "../../Component/Reusable/Loader/Loader";
import moment from 'moment';
import api from "../../Config/Api";
import {
  EyeOutlined,
  SearchOutlined,
  DownloadOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import { TransactionListAction } from "../../Action/Transaction";
import SiteLayout from "../Reusable/Layout/SiteLayout";
import "../Members/userstyle.scss";
import "../Members/style.css";
const { RangePicker } = DatePicker;
const { Option } = Select;

const Transactions = () => {
  const TransactionList = useSelector(state => state?.TransactionReducer?.TransactionList);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(10); 
  const [totalCount, setTotalCount] = useState(0); 
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [dfcNumber, setDfcNumber] = useState(""); 
  const [paymentMethod, setPaymentMethod] = useState("");


  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      await dispatch(TransactionListAction());
      setLoading(false);
    };
    fetchTransactions();
  }, [dispatch]);

  useEffect(() => {
    let filtered = TransactionList;
  
    // Date Range Filter
    if (dateRange?.length === 2) {
      const [startDate, endDate] = dateRange.map(date =>
        moment(date, "DD/MM/YYYY").format("DD/MM/YYYY")
      );
      filtered = filtered.filter((item) => {
        const receiptDate = moment(item?.paymentDetails?.[0]?.receiptDate, "DD/MM/YYYY");
        return receiptDate.isBetween(
          moment(startDate, "DD/MM/YYYY"),
          moment(endDate, "DD/MM/YYYY"),
          "days",
          "[]"
        );
      });
    }

    // DFC No Filter
    if (dfcNumber) {
      filtered = filtered.filter(item =>
        item?.dfcNumber?.toString()?.toLowerCase()?.includes(dfcNumber.toLowerCase()) 
      );
    }

    if (paymentMethod) {
      filtered = filtered.filter(item =>
        item?.paymentMethod?.toLowerCase() === paymentMethod.toLowerCase()
      );
    }
  
    setFilteredData(filtered);
  }, [dateRange, dfcNumber, TransactionList,paymentMethod]);
  
  const handleResetFilters = () => {
    setDateRange([]);
    setDfcNumber("");
    setPaymentMethod("");

  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
            <Button
            onClick={() => handleReset(clearFilters, setSelectedKeys)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            onClick={() => handleClear(setSelectedKeys, clearFilters, confirm)}
            size="small"
            danger
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  
  const handleReset = (clearFilters, setSelectedKeys) => {
    clearFilters();
    setSelectedKeys([]);
    setSearchText("");
    setSearchedColumn("");
    setFilteredData(TransactionList);
  };
  
  const handleClear = (setSelectedKeys, clearFilters, confirm) => {
    clearFilters();
    setSelectedKeys([]);
    setSearchText("");
    setSearchedColumn("");
    setFilteredData(TransactionList);
    confirm();
  };
  
  
  const dataSource = filteredData.map((data, index) => ({
    key: index,
    id: data?._id,
    dfcNo: data?.dfcNumber,
    amount: data?.amount,
    lateFees: data?.lateFees,
    paymentMethod: data?.paymentMethod,
    paymentDate: data?.paymentDate
      ? moment(data.paymentDate).format("DD/MM/YYYY")
      : "N/A",
    receiptDate: data?.paymentDetails?.length > 0
      ? data.paymentDetails[0].receiptDate 
      : "N/A",
    status: data?.status,
    receiptNumber: data?.receiptNumber,
  }));
  
  const generatePdfForTransaction = async (txnId) => {
    setPdfLoader(true);
    try {
      const response = await api.post(
        "/admin/downloadPdfForTransaction",
        { txnId },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Transaction_${txnId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error occurred while downloading the PDF file:", error);
      message.error('Failed to download PDF');
    }
    setPdfLoader(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  
  

  const handleDelete = async (txnId) => {
    try {
      const response = await api.delete(`/admin/deleteTransaction?txnId=${txnId}`);
      if (response.status === 200) {
        message.success('Transaction deleted successfully');      
        dispatch(TransactionListAction());
      } else {
        message.error('Failed to delete transaction');
      }
    } catch (error) {
      message.error('Transaction made through "Phonepe" is not Deletable');
      console.error("Error:", error);
    }
  };

  const columns = [
    {
      title: "DFC No.",
      dataIndex: "dfcNo",
      key: "dfcNo",
      // ...getColumnSearchProps("dfcNo", "DFC No."),
    },
    {
      title: "Receipt Date",
      dataIndex: "receiptDate",
      key: "receiptDate",
      // ...getColumnSearchProps("receiptDate", "Receipt Date"),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      ...getColumnSearchProps("amount", "Amount"),
      align: "right", 

    }, 
    {
      title: "Late Fees",
      dataIndex: "lateFees",
      key: "lateFees",
      align: "right", 
      ...getColumnSearchProps("lateFees", "Late Fees"),

    },
    {
      title: "Receipt No.",
      dataIndex: "receiptNumber",
      key: "receiptNumber",
      align: "right", 
      ...getColumnSearchProps("receiptNumber", "Receipt No."),
    },

    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      align: "right", 
      // ...getColumnSearchProps("paymentMethod", "Payment Method"),
    },
   
   
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "SUCCESS", value: "SUCCESS" },
        { text: "FAILED", value: "FAILED" },
        { text: "PENDING", value: "PENDING" },
        { text: "CANCELLED", value: "CANCELLED" },
      ],
      onFilter: (value, record) => record?.status?.toUpperCase() === value,
      render: (text, row) => (
        <Space size="middle">
          <Tag
            color={
              (["SUCCESS", "success"].includes(row?.status) && "blue") ||
              (["FAILED", "failed"].includes(row?.status) && "red") ||
              (["PENDING", "pending"].includes(row?.status) && "orange") ||
              (["CANCELLED", "cancelled"].includes(row?.status) && "gray")
            }
          >
            {row?.status}
          </Tag>
        </Space>
      ),
    },    
    {
      title: "Operation",
      render: (text, record) => (
        <Space size="middle">
          
          <Tooltip title="Download">
        <Button
          style={{
            background:
              record?.status?.toUpperCase() === "PENDING" 
                ? "#d9d9d9"
                : "black",
          }}
          type="primary"
          icon={<DownloadOutlined style={{ color: record?.status?.toUpperCase() === "PENDING"}} />}
          onClick={() => generatePdfForTransaction(record?.id)}
          disabled={record?.status?.toUpperCase() === "PENDING" }
        />
      </Tooltip>

      <Tooltip title="View">
        <Link to={`/transactionsdetails/${record?.id}`}>
          <Button type="primary" icon={<EyeOutlined />}  />
        </Link>
      </Tooltip>

      <Tooltip title="Edit">
        <Link to={`/edittransaction/${record?.id}`}>
          <Button
            type="primary"
            icon={<EditOutlined />}
            disabled={record.paymentMethod === "PHONEPE" }
          />
        </Link>
      </Tooltip>

      <Popconfirm
        title="Are you sure you want to delete this transaction?"
        onConfirm={() => handleDelete(record?.id)}
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        disabled={record?.status?.toUpperCase() === "CANCELLED"}
      >
        <Tooltip title="Delete">
          <Button danger type="primary" icon={<DeleteOutlined />} disabled={record?.status?.toUpperCase() === "CANCELLED"} />
        </Tooltip>
      </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <SiteLayout>
    {loading && <Loader />}
    <div style={{ padding: "30px" }} className="checkout-form-container gig-sure-card-wrapper">
      <div className="wrapper_twoDiv">
        <Space style={{ marginBottom: 10 }}>
          <h3 className="start">Transactions List</h3>
        </Space>
  
        <div style={{ display: 'flex', gap: '10px' }}>
        <Card title="Filter by Payment Method" style={{ width: '25%' }}>
          <Select
            placeholder="Select Payment Method"
            value={paymentMethod}
            onChange={setPaymentMethod}
            allowClear
            style={{ width: '100%' }}
          >
            <Option value="Cash">Cash</Option>
            <Option value="Cheque">Cheque</Option>
            <Option value="PhonePe">PhonePe</Option>
          </Select>
        </Card>

          <Card 
            title="Search by DFC No."
            style={{ width: '30%' }}
          >
            <Space size="middle">
              <Input
                placeholder="Enter DFC No."
                value={dfcNumber}
                onChange={(e) => setDfcNumber(e.target.value)}
                style={{ width: '100%' }}
              />
            </Space>
          </Card>
  
          {/*  Date Filter Card */}
          <Card 
            title="Filter by Receipt Date"
            style={{ width: '30%' }}
          >
            <Space size="middle">
              <RangePicker
                value={dateRange}
                onChange={setDateRange}
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
              />
              <Button onClick={handleResetFilters}>Reset</Button>
            </Space>
          </Card>
        </div>
  
        {/*  Transaction Table */}
        <Table
            dataSource={dataSource}
            columns={columns}
            onChange={(pagination, filters) => {
              setCurrentPage(pagination.current);
              setPageSize(pagination.pageSize);
              
              let filtered = TransactionList;
              
              if (filters.status && filters.status.length > 0) {
                filtered = filtered.filter(item => 
                  filters.status.includes(item.status?.toUpperCase())
                );
              }

              setFilteredData(filtered);
            }}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: totalCount,
              showSizeChanger: true,
            }}
          />

  
        {pdfLoader && <Loader message="Generating PDF..." />}
      </div>
    </div>
  </SiteLayout>
  
  );
};

export default Transactions;