import React, { useEffect, useState } from "react";
import {
  Table,
  message,
  Input,
  Card,
  DatePicker,
  Button,
  InputNumber,
} from "antd";
import SiteLayout from "../Reusable/Layout/SiteLayout";
import Loader from "../../Component/Reusable/Loader/Loader";
import "../Members/userstyle.scss";
import "../Members/style.css";
import api from "../../Config/Api";
import moment from "moment";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
import { SearchOutlined } from "@ant-design/icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReportPDF from "./ReportPDF";
const { RangePicker } = DatePicker;

const ReportTable = () => {
  const [allReportData, setAllReportData] = useState([]);
  const [filteredReportData, setFilteredReportData] = useState([]);
  const [dfcSearch, setDfcSearch] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [receiptRange, setReceiptRange] = useState([null, null]);
  const [chequeSearch, setChequeSearch] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({});

  useEffect(() => {
    fetchAllReports();
  }, []);

  const fetchAllReports = async () => {
    setLoading(true);
    try {
      const response = await api.get("/admin/getReport");
      if (response.status === 200) {
        const data = response.data.data || [];
        setAllReportData(data);
        setFilteredReportData(data);
      } else {
        message.error("Failed to fetch report data");
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
      message.error("An error occurred while fetching report data");
    } finally {
      setLoading(false);
    }
  };

  const handleDfcSearch = (e) => {
    const value = e.target.value;
    setDfcSearch(value);
    filterData(allReportData, dateRange, value, receiptRange, chequeSearch);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (!dates || dates.length === 0) {
      setFilteredReportData(allReportData);
      return;
    }
    filterData(allReportData, dates, dfcSearch, receiptRange, chequeSearch);
  };

  const handleChequeSearch = (value) => {
    setChequeSearch(value);
    filterData(allReportData, dateRange, dfcSearch, receiptRange, value);
  };

  const handleReceiptRangeChange = (value, index) => {
    const updatedRange = [...receiptRange];
    updatedRange[index] = value;
    setReceiptRange(updatedRange);

    // Make sure to pass all relevant parameters
    filterData(allReportData, dateRange, dfcSearch, updatedRange, chequeSearch);
  };

  const filterData = (
    data,
    dates,
    dfcSearchValue,
    receiptRangeValue,
    chequeSearchValue
  ) => {
    let filteredData = [...data];

    let filters = {};
    // Filter by DFC Number
    if (dfcSearchValue) {
      const searchValue = String(dfcSearchValue).toLowerCase();
      filteredData = filteredData.filter((item) =>
        String(item.dfcNumber || "")
          .toLowerCase()
          .includes(searchValue)
      );
      filters.dfcNumber = dfcSearchValue;
    }

    // Filter by Date Range
    if (dates && dates.length === 2 && dates[0] && dates[1]) {
      const [start, end] = dates.map((date) =>
        moment(date).format("DD/MM/YYYY")
      );
      filteredData = filteredData.filter((item) => {
        const receiptDate = item.receiptDate;
        return receiptDate >= start && receiptDate <= end;
      });
      filters.dateRange = `${start} to ${end}`;
    }

    // Filter by Receipt Number Range
    if (
      receiptRangeValue &&
      receiptRangeValue[0] !== null &&
      receiptRangeValue[1] !== null
    ) {
      filteredData = filteredData.filter((item) => {
        const receiptNumber = parseInt(item.receiptNumber) || 0;
        return (
          receiptNumber >= receiptRangeValue[0] &&
          receiptNumber <= receiptRangeValue[1]
        );
      });

      filters.receiptRange = `${receiptRangeValue[0]} to ${receiptRangeValue[1]}`;
    }

    // Filter by Cheque Number
    if (chequeSearchValue !== null) {
      filteredData = filteredData.filter((item) =>
        String(item.chequeNo || "").includes(String(chequeSearchValue))
      );
      filters.chequeNumber = chequeSearchValue;
    }

    setFilteredReportData(filteredData);
    setAppliedFilters(filters);
  };

  //   const downloadPDF = () => {
  //     const doc = new jsPDF({ orientation: "landscape" });

  //     doc.addFont("./fonts/AnekGujarati-VariableFont_wdth,wght.ttf", "AnekGujarati", "normal");
  //     doc.addFont("./fonts/AnekGujarati-VariableFont_wdth,wght.ttf", "AnekGujarati", "ExtraBold");

  //     doc.setFont("AnekGujarati");

  //     // Add logo (left and right)
  //     const logoPath = "./images/logo.png";
  //     const logoPath2 = "./images/logo2.png";

  //     const logoWidth = 30;
  //     const logoHeight = 30;

  //     doc.addImage(logoPath, "PNG", 20, 15, logoWidth, logoHeight); // Left
  //     const pageWidth = doc.internal.pageSize.width;
  //     const rightLogoX = pageWidth - logoWidth - 20;

  //     doc.addImage(logoPath2, "PNG", rightLogoX, 15, logoWidth, logoHeight);
  //     doc.setFontSize(12);
  //     doc.setFont("AnekGujarati", "normal");
  //     doc.text("|| શ્રી ગણેશાય નમઃ ||", 145, 10, { align: "center"});

  //     doc.setFontSize(20);
  //     doc.setFont("AnekGujarati", "ExtraBold");
  //     doc.text("શ્રી દશા શ્રી માળી સુખડીયા સુરક્ષા ટ્ર", 145, 20, { align: "center" });

  //     doc.setFontSize(13);
  //     doc.setFont("AnekGujarati", "normal");
  //     doc.text("ર.નં.અે-૩૮૧૨, ૨.તા .૨૪-૫-૨૦૦૭", 145, 27, { align: "center" });

  //     // Address
  //     doc.setFontSize(13);
  //     doc.text("Trust Address: સેલર/9, મહાવીર ટાવર, એચ.પી. પેટ્રોલ પંપ,મહાલક્ષ્મી ચાર રસ્તા પાસે,", 145, 38, { align: "center" });
  //     doc.text("પાલડી, અમદાવાદ - 380007", 145, 44, { align: "center" });

  //     doc.setLineWidth(0.8);
  //     doc.line(10, 50, 280, 50);

  //     // Add report date (current date)
  //     const todayDate = new Date().toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
  //     let yPos = 58;
  //     doc.setFontSize(12);
  //     doc.text(`Report Date: ${todayDate}`, 20, yPos);
  //     yPos += 7;

  //     // Display applied filters
  //     if (appliedFilters.dfcNumber) {
  //         doc.text(`DFC Number: ${appliedFilters.dfcNumber}`, 20, yPos);
  //         yPos += 7;
  //     }

  //     if (appliedFilters.dateRange) {
  //         doc.text(`Date Range: ${appliedFilters.dateRange}`, 20, yPos);
  //         yPos += 7;
  //     }

  //     if (appliedFilters.receiptRange) {
  //         doc.text(`Receipt No Range: ${appliedFilters.receiptRange}`, 20, yPos);
  //         yPos += 7;
  //     }

  //     doc.line(10, yPos, 280, yPos);
  //     yPos += 10;

  //     // (Rest of your code remains unchanged)

  //     doc.save("Report.pdf");

  //     //table headers
  //     const tableColumn = [
  //       "Member No","DFC No", "Name", "City", "Mobile", "Receipt No",
  //        "Receipt Date", "Cheque No", "Bank Name", "Branch Name",
  //       "Payment Method", "Receipt Amount","Late Fees", "Cheque Charge",
  //     ];

  //     const filteredRowsForDisplay = filteredReportData.filter((item) => item.fees);

  //     const tableRows = filteredRowsForDisplay.map((item) => [
  //       item.userMemberNo || "N/A",
  //       item.dfcNumber || "N/A",
  //       item.userName || "N/A",
  //       item.userCity || "N/A",
  //       // item.userState || "N/A",
  //       item.userMobile || "N/A",
  //       item.receiptNumber || "N/A",
  //       item.receiptDate || "N/A",
  //       item.chequeNo || "N/A",
  //       item.bankName || "N/A",
  //       item.branchName || "N/A",
  //       item.paymentMethod || "N/A",
  //       item.amount || 0,
  //       item.lateFees || 0,
  //       item.chequeReturnCharge || 0,
  //       item.Maintaince_charge || 0,
  //     ]);

  //     // Calculate totals
  //     const totalFees = filteredReportData.reduce((sum, item) => sum + (item.fees || 0), 0);
  //     const totalLateFees = filteredReportData.reduce((sum, item) => sum + (item.lateFees || 0), 0);
  //     const totalChequeReturnCharge = filteredReportData.reduce((sum, item) => sum + (item.chequeReturnCharge || 0), 0);
  //     const totalMaintenanceCharge = filteredReportData.reduce((sum, item) => sum + (item.Maintaince_charge || 0), 0);
  //     const grandTotal = totalFees + totalLateFees + totalChequeReturnCharge + totalMaintenanceCharge;

  //     // Add main data table (starting after filters)
  //     doc.autoTable({
  //       head: [tableColumn],
  //       body: tableRows,
  //       startY: yPos, // Adjusted start position after filters
  //       styles: { font: "AnekGujarati", fontSize: 9, cellPadding: 2 },
  //       headStyles: { fillColor: [0, 128, 255], textColor: 255, fontSize: 10 },
  //       alternateRowStyles: { fillColor: [240, 240, 240] },
  //     });

  //     // Add totals table
  //     const smallTableColumn = [
  //       "Total Records", "Total Fees", "Late Fees", "Cheque Return", "Maintenance", "Grand Total",
  //     ];
  //     const smallTableRows = [
  //       [
  //         filteredReportData.length,
  //         `₹${totalFees.toFixed(2)}`,
  //         `₹${totalLateFees.toFixed(2)}`,
  //         `₹${totalChequeReturnCharge.toFixed(2)}`,
  //         `₹${totalMaintenanceCharge.toFixed(2)}`,
  //         `₹${grandTotal.toFixed(2)}`,
  //       ],
  //     ];

  //     doc.autoTable({
  //       head: [smallTableColumn],
  //       body: smallTableRows,
  //       startY: doc.autoTable.previous.finalY + 12,
  //       styles: { font: "AnekGujarati", fontSize: 10, cellPadding: 2 },
  //       headStyles: { fillColor: [0, 128, 255], textColor: 255, fontSize: 10 },
  //       bodyStyles: { fillColor: [255, 255, 255] },
  //       columnStyles: {
  //         1: { halign: "left" },
  //         2: { halign: "left" },
  //         3: { halign: "left" },
  //         4: { halign: "left" },
  //         5: { halign: "left", fontStyle: "bold" },
  //       },
  //     });

  //     doc.save("Report.pdf");
  // };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleClearAll = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
    setSearchedColumn("");
    setFilteredReportData(allReportData);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Reset
        </Button>
        <Button
          onClick={() => handleClearAll(clearFilters, confirm)}
          size="small"
          danger
          style={{ width: 90 }}
        >
          Clear
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "receiptDate") {
        // Convert to 'DD-MM-YYYY' format for comparison
        const formattedDate = record[dataIndex]
          ? moment(new Date(record[dataIndex])).format("DD-MM-YYYY")
          : "";
        return formattedDate.includes(value);
      }
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false;
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ backgroundColor: "#ffc069", padding: 0 }}>{text}</span>
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Member No",
      dataIndex: "userMemberNo",
      key: "userMemberNo",
      ...getColumnSearchProps("userMemberNo"),
    },
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",
      ...getColumnSearchProps("userName"),
    },
    {
      title: "City",
      dataIndex: "userCity",
      key: "userCity",
      ...getColumnSearchProps("userCity"),
    },
    {
      title: "State",
      dataIndex: "userState",
      key: "userState",
      ...getColumnSearchProps("userState"),
    },
    {
      title: "Mobile",
      dataIndex: "userMobile",
      key: "userMobile",
      ...getColumnSearchProps("userMobile"),
    },
    {
      title: "DFC Number",
      dataIndex: "dfcNumber",
      key: "dfcNumber",
      render: (dfcNumber) => dfcNumber || "N/A",
      ...getColumnSearchProps("dfcNumber"),
    },
    {
      title: "Receipt Number",
      dataIndex: "receiptNumber",
      key: "receiptNumber",
      ...getColumnSearchProps("receiptNumber"),
    },
    {
      title: "Receipt Date",
      dataIndex: "receiptDate",
      key: "receiptDate",
      render: (text) => text || "N/A",
      ...getColumnSearchProps("receiptDate"),
    },
    {
      title: "Cheque No",
      dataIndex: "chequeNo",
      key: "chequeNo",
      ...getColumnSearchProps("chequeNo"),
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      ...getColumnSearchProps("bankName"),
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
      ...getColumnSearchProps("branchName"),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      ...getColumnSearchProps("paymentMethod"),
    },
    {
      title: "Receipt Amount",
      dataIndex: "amount",
      key: "amount",
      ...getColumnSearchProps("amount"),
    },
    {
      title: "Contribution",
      dataIndex: "fees",
      key: "fees",
      ...getColumnSearchProps("fees"),
    },
    {
      title: "Late Fees",
      dataIndex: "lateFees",
      key: "lateFees",
      ...getColumnSearchProps("lateFees"),
    },
    {
      title: "Cheque Return Charge",
      dataIndex: "chequeReturnCharge",
      key: "chequeReturnCharge",
      render: (value) => value || 0,
      ...getColumnSearchProps("chequeReturnCharge"),
    },
    {
      title: "Maintenance Charge",
      dataIndex: "Maintaince_charge",
      key: "Maintaince_charge",
      ...getColumnSearchProps("Maintaince_charge"),
    },
  ];

  return (
    <SiteLayout>
      {loading && <Loader />}
      {!loading && error && <div>{error}</div>}
      <div>
        <h2>Report</h2>
        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "flex-start",
            width: "100%",
            margin: "10px",
          }}
        >
          {/* DFC Number Search */}
          <Card bordered style={{ width: "20%", padding: 10 }}>
            <p style={{ marginBottom: 10, fontWeight: "bold" }}>
              Search DFC Number
            </p>
            <Input
              placeholder="Enter DFC Number"
              allowClear
              onChange={handleDfcSearch}
              value={dfcSearch}
              style={{ width: "100%" }}
            />
          </Card>

          {/* Date Range Picker */}
          <Card bordered style={{ width: "30%", padding: 10 }}>
            <p style={{ marginBottom: 10, fontWeight: "bold" }}>
              Select Date Range
            </p>
            <RangePicker
              format="DD/MM/YYYY"
              onChange={handleDateChange}
              style={{ width: "100%" }}
            />
          </Card>

          {/* Receipt Number Range Picker */}
          <Card bordered style={{ width: "30%", padding: 10 }}>
            <p style={{ marginBottom: 10, fontWeight: "bold" }}>
              Receipt Number Range
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              <InputNumber
                placeholder="Min"
                value={receiptRange[0]}
                onChange={(value) => handleReceiptRangeChange(value, 0)}
                style={{ width: "45%" }}
              />
              <InputNumber
                placeholder="Max"
                value={receiptRange[1]}
                onChange={(value) => handleReceiptRangeChange(value, 1)}
                style={{ width: "45%" }}
              />
            </div>
          </Card>

          {/* Cheque Number Search */}
          <Card bordered style={{ width: "30%", padding: 10 }}>
            <p style={{ marginBottom: 10, fontWeight: "bold" }}>
              Search Cheque Number
            </p>
            <InputNumber
              placeholder="Enter Cheque Number"
              value={chequeSearch}
              onChange={handleChequeSearch}
              style={{ width: "100%" }}
            />
          </Card>
        </div>

        {/* Download PDF Button */}

        <PDFDownloadLink
          document={
            <ReportPDF
              data={filteredReportData}
              appliedFilters={appliedFilters}
            />
          }
          fileName="Report.pdf"
          style={{ textDecoration: "none" }}
        >
          {({ loading }) => (
            <Button type="primary" disabled={loading}>
              {loading ? "Generating PDF..." : "Download PDF"}
            </Button>
          )}
        </PDFDownloadLink>
        {/* Report Table */}
        <Table
          columns={columns}
          dataSource={filteredReportData}
          rowKey={(record, index) => `${record.userMemberNo}-${index}`}
          bordered
          footer={() => {
            const totalFees = filteredReportData.reduce(
              (sum, record) => sum + (record.fees || 0),
              0
            );
            const totalLateFees = filteredReportData.reduce(
              (sum, record) => sum + (record.lateFees || 0),
              0
            );
            const totalChequeReturnCharge = filteredReportData.reduce(
              (sum, record) => sum + (record.chequeReturnCharge || 0),
              0
            );
            const totalMaintenanceCharge = filteredReportData.reduce(
              (sum, record) => sum + (record.Maintaince_charge || 0),
              0
            );

            const grandTotal =
              totalFees +
              totalLateFees +
              totalChequeReturnCharge +
              totalMaintenanceCharge;

            return (
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "right",
                  padding: "10px",
                }}
              >
                <span>Total Records: {filteredReportData.length} | </span>
                <span>Total Fees: ₹{totalFees.toFixed(2)} | </span>
                <span>Total Late Fees: ₹{totalLateFees.toFixed(2)} | </span>
                <span>
                  Total Cheque Return Charges: ₹
                  {totalChequeReturnCharge.toFixed(2)} |{" "}
                </span>
                <span>
                  Total Maintenance Charges: ₹
                  {totalMaintenanceCharge.toFixed(2)} |{" "}
                </span>
                <span style={{ color: "blue" }}>
                  Grand Total: ₹{grandTotal.toFixed(2)}
                </span>
              </div>
            );
          }}
        />
      </div>
    </SiteLayout>
  );
};

export default ReportTable;
