import {
  ADD_MEMBER_ERROR,
  ADD_MEMBER_LOADING,
  ADD_MEMBER_SUCCESS,
  MEMBER_LIST,
  MEMBER_STATUS,
  LOADER,
  MEMBER_DETAILS,
  EDIT_MEMBER_LOADING,
  EDIT_MEMBER_ERROR,
  EDIT_MEMBER_SUCCESS,
  INITIALIZE_STATE,
} from "../Action/ActionTypes";
import api from "../Config/Api";
import { message } from "antd";

// Add
export const AddAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MEMBER_LOADING,
    });
    try {
      api.post("admin/users/save", payload).then(
        (response) => {
          if (response?.status === 200) {
            message.success(response?.data?.message);
            dispatch({
              type: ADD_MEMBER_SUCCESS,
              payload: response,
            });
          }
        },
        function (error) {
          if (error.response && error.response.status === 400) {
            message.error(error?.response?.data?.message);
          }
          // dispatch({
          //   type: ADD_MEMBER_ERROR,
          //   payload: error,
          // });
        }
      );
    } catch (err) {
      console.log("catch err");
      errorMessageConfig(dispatch);
    }
  };
};

// Select List
export const MemberListAction = ({ page = 1, pageSize = 10 } = {}) => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      api({
        url: "admin/users/list",
        method: "get",
        params: {
          limit: pageSize,
          page: page,
        },
      }).then(
        (response) => {
          let data = response.data.data.docs;
          let responseStatus = response.data.code;

          if (responseStatus === 200) {
            dispatch({
              type: MEMBER_LIST,
              payload: data,
              totalItems: response.data.data.total, // Include totalItems in the payload
            });
            dispatch(loader(false));
          }
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

// Delete
export const DeleteMemberAction = (payload) => {
  const config = {
    userIds: payload,
  };
  return (dispatch) => {
    dispatch(loader(true));
    try {
      api.post("admin/user/delete", config).then(
        (response) => {
          if (response.status === 200) {
            message.success(response.data.message);
            dispatch(loader(false));
            dispatch(MemberListAction());
          }
        },
        function (error) {
          if (error.response && error.response.status === 400) {
            message.error(error.response.data.message);
          }
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

// Details
export const MemberDetailAction = (userId) => {
  const config = {
    userId,
  };

  return (dispatch) => {
    dispatch(loader(true));
    try {
      api.post("admin/users/view", config).then(
        (response) => {
          let data = response?.data?.data;
          if (response?.status === 200) {
            dispatch({
              type: MEMBER_DETAILS,
              payload: data,
            });
            dispatch(loader(false));
          }
        },
        function (error) {
          console.log("function error");

          // if (error.response && error.response.status === 400) {
          // message.error(error.response.data.message);
          // }
        }
      );
    } catch (err) {
      console.log("catch-error");
      errorMessageConfig(dispatch);
    }
  };
};

export const EditAction = (id, payload, navigate) => {
  let config = { id: id, ...payload };

  return (dispatch) => {
    dispatch({
      type: EDIT_MEMBER_LOADING,
    });
    try {
      api.post("admin/users/edit", config).then(
        (response) => {
          console.log("edit-update", response);
          if (response?.status === 200) {
            message.success(response?.data?.message);
            dispatch({
              type: EDIT_MEMBER_SUCCESS,
              payload: response,
            });

            navigate("/members");
          }
        },
        function (error) {
          if (error.response && error.response.status === 400) {
            message.error(error?.response?.data?.message);
          }
          // dispatch({
          //   type: EDIT_MEMBER_ERROR,
          //   payload: error,
          // });
        }
      );
    } catch (err) {
      console.log("catch-error");
      errorMessageConfig(dispatch);
    }
  };
};

export const initializeState = (para) => {
  return (dispatch) => {
    dispatch({
      type: INITIALIZE_STATE,
    });
  };
};

function errorMessageConfig(dispatch) {
  dispatch(loader(false));
  message.error("Something Went Wrong");
}

export const MemberStatus = (payload) => {
  return (dispatch) => {

    try {
      api.post("admin/users/status", payload).then(
        (response) => {
          if (response?.status === 200) {
            message.success(response?.data?.message);
            dispatch({
              type: MEMBER_STATUS,
              payload: response?.data?.message,
            });
            dispatch(loader(false));
            dispatch(MemberListAction());
          }
        },
        function (error) {
          if (error.response && error.response.status === 400) {
            message.error(error?.response?.data?.message);
          }
          // dispatch({
          //   type: ADD_MEMBER_ERROR,
          //   payload: error,
          // });
        }
      );
    } catch (err) {
      console.log("catch err");
      errorMessageConfig(dispatch);
    }
  };
};

export const loader = (para) => {
  return {
    type: LOADER,
    payload: para,
  };
};
