import { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";

Font.register({
  family: "MuktaVaaniBold",
  src: "/fonts/MuktaVaani-Bold.ttf",
});
Font.register({
  family: "MuktaVaaniRegular",
  src: "/fonts/MuktaVaani-Regular.ttf",
});

// Define Styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "MuktaVaaniRegular",
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  logo: {
    width: 75,
    height: 75,
  },
  logo2: {
    width: 100,
    height: 100,
  },
  headerTextContainer: {
    flex: 1,
    textAlign: "center",
  },
  headerTextSmall: {
    fontSize: 12,
  },
  headerTextLarge: {
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "MuktaVaaniBold",
  },
  headerTextMedium: {
    fontSize: 12,
    marginTop: 1,
    fontFamily: "MuktaVaaniBold",
  },
  horizontalLine: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 5,
  },
  tableContainer: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 5,
    overflow: "hidden",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#ddd",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    fontFamily: "MuktaVaaniBold",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  column: {
    fontSize: 10,
    textAlign: "center",
    padding: 4,
    borderRightWidth: 0.4,
    borderRightColor: "#000",
  },
  totalsTableContainer: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 5,
    overflow: "hidden",
    width: "100%",
  },
  totalsTableHeader: {
    flexDirection: "row",
    backgroundColor: "#ddd",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    fontFamily: "MuktaVaaniBold",
  },
  totalsTableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  totalsTableFooter: {
    flexDirection: "row",
    backgroundColor: "#f5f5f5",
    borderTopWidth: 1,
    borderTopColor: "#000",
  },
  totalsColumn: {
    flex: 1,
    fontSize: 10,
    textAlign: "center",
    padding: 6,
    borderRightWidth: 1,
    borderRightColor: "#000",
    fontFamily: "MuktaVaaniBold",
  },
  headerColumn: {
    fontWeight: "bold",
    fontFamily: "MuktaVaaniBold",
  },
  footerColumn: {
    fontFamily: "MuktaVaaniBold",
  },
  columnWidths: {
    srNo: "10%",
    receiptNumber: "15%",
    dfcNumber: "10%",
    totalMembers: "10%",
    chequeNo: "15%",
    bankName: "15%",
    branchName: "15%",
    paymentMethod: "15%",
    amount: "15%",
    chequeReturnCharge: "15%",
  },
  filtersContainer: {
    backgroundColor: "#f9f9f9",
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 8,
    marginVertical: 10,
    width: "100%",
    fontFamily: "MuktaVaaniBold",
  },
  filtersTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 4,
    color: "#333",
  },
  filterItem: {
    fontSize: 11,
    color: "#555",
    marginBottom: 2,
  },
});

const Report1PDF = ({ data, filters }) => {
  const totalAmount = data.reduce((acc, item) => acc + (item.amount || 0), 0);
  const totalChequeReturnCharge = data.reduce(
    (acc, item) => acc + (item.chequeReturnCharge || 0),
    0
  );
  const grandTotal = totalAmount + totalChequeReturnCharge;
  const [reportDate, setReportDate] = useState("");

  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    setReportDate(getFormattedDate());
  }, []);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.headerContainer}>
          <Image src="/images/logo.png" style={styles.logo} />
          <View style={styles.headerTextContainer}>
            <Text style={styles.headerTextSmall}>|| શ્રી ગણેશાય નમઃ ||</Text>
            <Text style={styles.headerTextLarge}>
              શ્રી સમસ્ત દશાશ્રીમાળી સુખડીયા સુરક્ષા ટ્રસ્ટ
            </Text>
            <Text style={styles.headerTextMedium}>
              રજી.નં.એ-૩૮૧૨, ૨જી.તા.૨૪-૫-૨૦૦૭
            </Text>
            <Text style={styles.headerTextMedium}>
              Trust Address: સેલર/9, મહાવીર ટાવર, એચ.પી. પેટ્રોલ પંપ, મહાલક્ષ્મી
              ચાર રસ્તા પાસે,
            </Text>
            <Text style={styles.headerTextMedium}>પાલડી, અમદાવાદ - 380007</Text>
          </View>
          <Image src="/images/logo3.png" style={styles.logo2} />
        </View>
        <View style={styles.horizontalLine} />

        <View style={styles.filtersContainer}>
          <Text style={styles.filterItem}>Report Date : {reportDate}</Text>
          {filters.dfcNumber && (
            <Text style={styles.filterItem}>
              DFC Number: {filters.dfcNumber}
            </Text>
          )}
          {filters.dateRange && (
            <Text style={styles.filterItem}>
              Date Range: {filters.dateRange}
            </Text>
          )}
          {filters.receiptRange && (
            <Text style={styles.filterItem}>
              Receipt Range: {filters.receiptRange}
            </Text>
          )}
          {filters.chequeNumber && (
            <Text style={styles.filterItem}>
              Cheque Number: {filters.chequeNumber}
            </Text>
          )}
          {filters.paymentMethod && (
            <Text style={styles.filterItem}>
              Payment Method: {filters.paymentMethod}
            </Text>
          )}
        </View>

        {/* Table Header */}
        <View style={styles.tableContainer}>
          <View style={styles.tableHeader}>
            <Text style={[styles.column, { width: styles.columnWidths.srNo }]}>
              Sr No
            </Text>
            <Text
              style={[
                styles.column,
                { width: styles.columnWidths.receiptNumber },
              ]}
            >
              Receipt No
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.dfcNumber }]}
            >
              DFC No
            </Text>
            <Text
              style={[
                styles.column,
                { width: styles.columnWidths.totalMembers },
              ]}
            >
              No of Members
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.chequeNo }]}
            >
              Cheque No
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.bankName }]}
            >
              Bank Name
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.branchName }]}
            >
              Branch
            </Text>
            <Text
              style={[
                styles.column,
                { width: styles.columnWidths.paymentMethod },
              ]}
            >
              Payment Method
            </Text>
            <Text
              style={[styles.column, { width: styles.columnWidths.amount }]}
            >
              Amount
            </Text>
            <Text
              style={[
                styles.column,
                { width: styles.columnWidths.chequeReturnCharge },
              ]}
            >
              Cheque Return Charge
            </Text>
          </View>

          {/* Table Rows */}
          {data.length > 0 ? (
            data.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                <Text
                  style={[styles.column, { width: styles.columnWidths.srNo }]}
                >
                  {index + 1}
                </Text>
                <Text
                  style={[
                    styles.column,
                    { width: styles.columnWidths.receiptNumber },
                  ]}
                >
                  {item.receiptNumber || "N/A"}
                </Text>
                <Text
                  style={[
                    styles.column,
                    { width: styles.columnWidths.dfcNumber },
                  ]}
                >
                  {item.dfcNumber || "N/A"}
                </Text>
                <Text
                  style={[
                    styles.column,
                    { width: styles.columnWidths.totalMembers },
                  ]}
                >
                  {item.totalMembers || 0}
                </Text>
                <Text
                  style={[
                    styles.column,
                    { width: styles.columnWidths.chequeNo },
                  ]}
                >
                  {item.chequeNo || "N/A"}
                </Text>
                <Text
                  style={[
                    styles.column,
                    { width: styles.columnWidths.bankName },
                  ]}
                >
                  {item.bankName || "N/A"}
                </Text>
                <Text
                  style={[
                    styles.column,
                    { width: styles.columnWidths.branchName },
                  ]}
                >
                  {item.branchName || "N/A"}
                </Text>
                <Text
                  style={[
                    styles.column,
                    { width: styles.columnWidths.paymentMethod },
                  ]}
                >
                  {item.paymentMethod || "N/A"}
                </Text>
                <Text
                  style={[styles.column, { width: styles.columnWidths.amount }]}
                >
                  {item.amount
                    ? item.amount.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </Text>

                <Text
                  style={[
                    styles.column,
                    { width: styles.columnWidths.chequeReturnCharge },
                  ]}
                >
                  {item.chequeReturnCharge
                    ? item.chequeReturnCharge.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </Text>
              </View>
            ))
          ) : (
            <Text>No Data Available</Text>
          )}
        </View>
        {/* Summary Section */}
        <View style={styles.totalsTableContainer}>
          {/* Table Header */}
          <View style={styles.totalsTableHeader}>
            <Text style={styles.totalsColumn}>Summary</Text>
            <Text style={styles.totalsColumn}>Amount (₹)</Text>
          </View>

          {/* Table Rows */}
          <View style={styles.totalsTableRow}>
            <Text style={styles.totalsColumn}>Total Records</Text>
            <Text style={styles.totalsColumn}>{data.length}</Text>
          </View>

          <View style={styles.totalsTableRow}>
            <Text style={styles.totalsColumn}>Total Amount</Text>
            <Text style={styles.totalsColumn}>
              {totalAmount.toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </View>

          <View style={styles.totalsTableRow}>
            <Text style={styles.totalsColumn}>Total Cheque Return Charge</Text>
            <Text style={styles.totalsColumn}>
              {totalChequeReturnCharge.toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </View>

          {/* Grand Total Row */}
          <View style={styles.totalsTableRow}>
            <Text style={[styles.totalsColumn, styles.footerColumn]}>
              Grand Total
            </Text>
            <Text style={[styles.totalsColumn, styles.footerColumn]}>
              {grandTotal.toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Report1PDF;
