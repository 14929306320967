import React, { useEffect, useState } from "react";
import {
  Table,
  message,
  Input,
  Card,
  DatePicker,
  Button,
  InputNumber,
  Select,
} from "antd";
import SiteLayout from "../Reusable/Layout/SiteLayout";
import Loader from "../Reusable/Loader/Loader";
import api from "../../Config/Api";
import moment from "moment";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Report1PDF from "./Report1PDF";
const { Option } = Select;

const { RangePicker } = DatePicker;

const Report1 = () => {
  const [allChequeData, setAllChequeData] = useState([]);
  const [filteredChequeData, setFilteredChequeData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [chequeSearch, setChequeSearch] = useState("");
  const [receiptRange, setReceiptRange] = useState([null, null]);
  const [dfcSearch, setDfcSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalChequeReturnCharge, setTotalChequeReturnCharge] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({});

  const fetchChequeReports = async () => {
    setLoading(true);
    try {
      const response = await api.get("/admin/getReport");
      if (response.status === 200) {
        const data = response.data.data || [];
        const groupedData = groupDataByReceiptNumber(data);
        setAllChequeData(groupedData);
        setFilteredChequeData(groupedData);
        updateTotals(groupedData);
      } else {
        message.error("Failed to fetch cheque report data");
      }
    } catch (error) {
      console.error("Error fetching cheque report data:", error);
      message.error("An error occurred while fetching cheque report data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChequeReports();
  }, []);

  const groupDataByReceiptNumber = (data) => {
    const groupedMap = new Map();

    data.forEach((item) => {
      if (!item.receiptNumber) return;

      if (!groupedMap.has(item.receiptNumber)) {
        groupedMap.set(item.receiptNumber, {
          receiptNumber: item.receiptNumber,
          chequeNo: item.chequeNo || "N/A",
          bankName: item.bankName || "N/A",
          branchName: item.branchName || "N/A",
          amount: 0,
          chequeReturnCharge: 0,
          totalMembers: new Set(),
          dfcNumber: item.dfcNumber || "N/A",
          paymentMethod: item.paymentMethod || "N/A",
          receiptDate: item.receiptDate || "N/A",
        });
      }

      const current = groupedMap.get(item.receiptNumber);

      if (item.userId) {
        current.totalMembers.add(item.userId.toString());
      }

      current.amount = item.amount || 0;
      current.chequeReturnCharge += item.chequeReturnCharge || 0;

      groupedMap.set(item.receiptNumber, current);
    });

    return Array.from(groupedMap.values()).map((item) => ({
      ...item,
      totalMembers: item.totalMembers.size,
    }));
  };

  const handleDfcSearch = (e) => {
    setDfcSearch(e.target.value);
    filterData(dateRange, e.target.value, chequeSearch, receiptRange);
  };

  const handleChequeSearch = (value) => {
    setChequeSearch(value);
    filterData(dateRange, dfcSearch, value, receiptRange);
  };

  const handleReceiptRangeChange = (value, index) => {
    const newRange = [...receiptRange];
    newRange[index] = value;
    setReceiptRange(newRange);
    filterData(dateRange, dfcSearch, chequeSearch, newRange);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
    filterData(dates, dfcSearch, chequeSearch, receiptRange);
  };
  const updateTotals = (data) => {
    const totalAmount = Math.round(
      data.reduce((sum, item) => sum + (item.amount || 0), 0)
    );
    const totalChequeReturnCharge = Math.round(
      data.reduce((sum, item) => sum + (item.chequeReturnCharge || 0), 0)
    );
    setTotalAmount(totalAmount);
    setTotalChequeReturnCharge(totalChequeReturnCharge);
    setGrandTotal(totalAmount + totalChequeReturnCharge);
    setTotalDocuments(data.length);
  };

  const filterData = (
    dates,
    dfcValue,
    chequeValue,
    receiptRangeValue,
    paymentMethodValue
  ) => {
    let filteredData = [...allChequeData];
    let newFilters = {}; // Store applied filters

    // Filter by Date Range
    if (dates && dates.length === 2) {
      const [start, end] = dates.map((date) =>
        moment(date, "DD/MM/YYYY").format("DD/MM/YYYY")
      );

      filteredData = filteredData.filter((item) => {
        const receiptDate = moment(item.receiptDate, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        );
        return moment(receiptDate, "DD/MM/YYYY").isBetween(
          moment(start, "DD/MM/YYYY"),
          moment(end, "DD/MM/YYYY"),
          undefined,
          "[]"
        );
      });
      newFilters.dateRange = `${start} to ${end}`;
    }

    // Filter by DFC Number
    if (dfcValue) {
      filteredData = filteredData.filter((item) =>
        String(item.dfcNumber || "").includes(String(dfcValue))
      );
      newFilters.dfcNumber = dfcValue;
    }

    // Filter by Cheque Number
    if (chequeValue) {
      filteredData = filteredData.filter((item) =>
        String(item.chequeNo || "").includes(String(chequeValue))
      );
      newFilters.chequeNumber = chequeValue;
    }

    // Filter by Receipt Number Range
    if (receiptRangeValue[0] !== null || receiptRangeValue[1] !== null) {
      filteredData = filteredData.filter((item) => {
        const receiptNo = parseInt(item.receiptNumber);
        if (receiptRangeValue[0] !== null && receiptNo < receiptRangeValue[0])
          return false;
        if (receiptRangeValue[1] !== null && receiptNo > receiptRangeValue[1])
          return false;
        return true;
      });
      newFilters.receiptRange = `${receiptRangeValue[0]} to ${receiptRangeValue[1]}`;
    }

    // Filter by Payment Method
    if (paymentMethodValue) {
      filteredData = filteredData.filter(
        (item) =>
          item.paymentMethod?.toLowerCase() === paymentMethodValue.toLowerCase()
      );
      newFilters.paymentMethod = paymentMethodValue;
    }

    setFilteredChequeData(filteredData);
    setFilters(newFilters);
    updateTotals(filteredData);
  };

  // const downloadPDF = () => {
  //   const doc = new jsPDF({ orientation: "landscape" });

  //   doc.setFontSize(16);
  //   doc.text("Receipt Report", 14, 10);

  //   const tableColumn = [
  //     "Sr No",
  //     "Receipt No",
  //     "No of Members",
  //     "Cheque No",
  //     "Bank Name",
  //     "Branch",
  //     "Payment Method",
  //     "Amount",
  //     "Cheque Return Charge",
  //   ];

  //   const tableRows = filteredChequeData.map((item, index) => [
  //     index + 1,
  //     item.receiptNumber || "N/A",
  //     item.totalMembers || 0,
  //     item.chequeNo || "N/A",
  //     item.bankName || "N/A",
  //     item.branchName || "N/A",
  //     item.paymentMethod || "N/A",
  //     item.amount?.toLocaleString("en-IN") || 0,
  //     item.chequeReturnCharge?.toLocaleString("en-IN") || 0,
  //   ]);

  //   tableRows.push([
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "TOTAL",
  //     totalAmount.toLocaleString("en-IN"),
  //     totalChequeReturnCharge.toLocaleString("en-IN"),
  //   ]);

  //   tableRows.push([
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "GRAND TOTAL",
  //     grandTotal.toLocaleString("en-IN"),
  //     "",
  //   ]);

  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 20,
  //     styles: {
  //       fontSize: 8,
  //       cellPadding: 3,
  //       textColor: [0, 0, 0],
  //       lineColor: [0, 0, 0],
  //       lineWidth: 0.1,
  //     },
  //     headStyles: {
  //       fillColor: [0, 128, 255],
  //       textColor: 255,
  //       fontSize: 10,
  //       lineColor: [0, 0, 0],
  //       lineWidth: 0.2,
  //     },
  //     alternateRowStyles: {
  //       fillColor: [240, 240, 240],
  //     },
  //     rowStyles: (row) => {
  //       if (row.index === tableRows.length - 2) {
  //         return {
  //           fillColor: [255, 235, 156],
  //           textColor: [0, 0, 0],
  //           fontStyle: "bold",
  //         };
  //       }
  //       if (row.index === tableRows.length - 1) {
  //         return {
  //           fillColor: [255, 102, 102],
  //           textColor: [255, 255, 255],
  //           fontStyle: "bold",
  //         };
  //       }
  //     },
  //   });

  //   doc.save("Receipt_Report.pdf");
  // };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      key: "srNo",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Receipt No",
      dataIndex: "receiptNumber",
      key: "receiptNumber",
    },
    {
      title: "DFC Number",
      dataIndex: "dfcNumber",
      key: "dfcNumber",
    },
    {
      title: "Number of Members",
      dataIndex: "totalMembers",
      key: "totalMembers",
    },
    {
      title: "Cheque No",
      dataIndex: "chequeNo",
      key: "chequeNo",
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "Branch",
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value) => `₹${value || 0}`,
    },
    {
      title: "Cheque Return Charge",
      dataIndex: "chequeReturnCharge",
      key: "chequeReturnCharge",
      render: (value) => `₹${Math.round(value) || 0}`,
    },
    {
      title: "Receipt Date",
      dataIndex: "receiptDate",
      key: "receiptDate",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
  ];

  return (
    <SiteLayout>
      {loading && <Loader />}

      {/* Filters Section */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gap: "16px",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        {/* DFC Number Search */}
        <Card bordered style={{ padding: "12px" }}>
          <p
            style={{ marginBottom: "8px", fontWeight: "600", fontSize: "14px" }}
          >
            Search DFC Number
          </p>
          <Input
            placeholder="Enter DFC Number"
            allowClear
            onChange={handleDfcSearch}
            value={dfcSearch}
            style={{ width: "100%" }}
          />
        </Card>

        {/* Receipt Number Range */}
        <Card bordered style={{ padding: "12px" }}>
          <p
            style={{ marginBottom: "8px", fontWeight: "600", fontSize: "14px" }}
          >
            Receipt Number Range
          </p>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <InputNumber
              placeholder="Min"
              value={receiptRange[0]}
              onChange={(value) => handleReceiptRangeChange(value, 0)}
              style={{ width: "40%" }}
            />
            <InputNumber
              placeholder="Max"
              value={receiptRange[1]}
              onChange={(value) => handleReceiptRangeChange(value, 1)}
              style={{ width: "40%" }}
            />
            <Button
              size="small"
              type="default"
              onClick={() => {
                setReceiptRange([null, null]);
                filterData(
                  dateRange,
                  dfcSearch,
                  chequeSearch,
                  [null, null],
                  paymentMethod
                );
              }}
              style={{
                padding: "0px 8px",
                height: "24px",
                fontSize: "12px",
                lineHeight: "22px",
              }}
            >
              Clear
            </Button>
          </div>
        </Card>

        {/* Date Range Picker */}
        {/* Date Range Picker */}
        <Card bordered style={{ padding: "12px" }}>
          <p
            style={{ marginBottom: "8px", fontWeight: "600", fontSize: "14px" }}
          >
            Select Date Range
          </p>
          <RangePicker
            format="DD/MM/YYYY"
            onChange={handleDateChange}
            style={{ width: "100%" }}
          />
        </Card>

        {/* Cheque Number Search */}
        <Card bordered style={{ padding: "12px" }}>
          <p
            style={{ marginBottom: "8px", fontWeight: "600", fontSize: "14px" }}
          >
            Search Cheque Number
          </p>
          <Input
            placeholder="Enter Cheque Number"
            allowClear
            onChange={(e) => handleChequeSearch(e.target.value)}
            value={chequeSearch}
            style={{ width: "100%" }}
          />
        </Card>

        {/* Payment Method Filter */}
        <Card bordered style={{ padding: "12px" }}>
          <p
            style={{ marginBottom: "8px", fontWeight: "600", fontSize: "14px" }}
          >
            Payment Method
          </p>
          <Select
            placeholder="Select Payment Method"
            value={paymentMethod}
            onChange={(value) => {
              setPaymentMethod(value);
              filterData(
                dateRange,
                dfcSearch,
                chequeSearch,
                receiptRange,
                value
              );
            }}
            style={{ width: "100%" }}
            allowClear
          >
            <Option value="Cash">Cash</Option>
            <Option value="Cheque">Cheque</Option>
            <Option value="PhonePe">PhonePe</Option>
          </Select>
        </Card>
      </div>
      {/* Download Button */}
      <PDFDownloadLink
        document={<Report1PDF data={filteredChequeData} filters={filters} />}
        fileName="Receipt_Report.pdf"
      >
        {({ loading }) => (
          <Button type="primary" disabled={loading}>
            {loading ? "Generating PDF..." : "Download PDF"}
          </Button>
        )}
      </PDFDownloadLink>

      {/* Table Section */}
      <Table
        columns={columns}
        dataSource={filteredChequeData}
        rowKey="_id"
        bordered
        style={{ marginTop: "20px" }}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setPagination({ current: page, pageSize });
          },
        }}
        footer={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Total Documents: {totalDocuments}</span>
            <span>
              Total Amount: ₹
              {totalAmount.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
              })}
            </span>
            <span>
              Total Cheque Return Charge: ₹
              {totalChequeReturnCharge.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
              })}
            </span>
            <span>
              <strong>
                Grand Total: ₹
                {grandTotal.toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                })}
              </strong>
            </span>
          </div>
        )}
      />
    </SiteLayout>
  );
};

export default Report1;
